import Vue from 'vue';
import VueI18n from 'vue-i18n';
//import API from '@/api';
import Services from '@/config/_axios';

Vue.use(VueI18n);

const qs = new URLSearchParams(window.location.search);

if (qs.has('lang')) {
  sessionStorage.setItem('lang', qs.get('lang'));
  sessionStorage.removeItem('langMessage');
}

let lang = sessionStorage.getItem('lang');
let langMessage = JSON.parse(sessionStorage.getItem('langMessage'));

if (!lang) {
  const detectLang = navigator.languages ? navigator.languages[0] : navigator.language || navigator.userLanguage;
  if (detectLang === 'tr' || detectLang === 'tr-TR') {
    lang = 'tr';
  } else if (detectLang === 'nl' || detectLang === 'nl-NL') {
    lang = 'nl';
  } else if (detectLang === 'me' || detectLang === 'me-ME') {
    lang = 'me';
  } else if (detectLang === 'et' || detectLang === 'et-ET') {
    lang = 'et';
  } else if (detectLang === 'de' || detectLang === 'de-DE') {
    lang = 'de';
  } else {
    lang = 'en';
  }
}

const i18n = new VueI18n({
  locale: lang,
  silentTranslationWarn: true
});
document.documentElement.setAttribute('lang', lang);

if (!langMessage) {
  changeLanguages(lang, false);
} else {
  i18n.setLocaleMessage(lang, langMessage || {});
}

export function changeLanguages(lang, refresh) {
  sessionStorage.setItem('lang', lang);
  //Services.get(`${API.localization}${lang}.json?ts=${new Date().getTime()}`).then((res) => {
  Services.get(`${window.location.origin}/assets/languages/${lang}.json?ts=${new Date().getTime()}`)
    .then((res) => {
      i18n.setLocaleMessage(lang, res.data || {});
      sessionStorage.setItem('langMessage', JSON.stringify(res.data));
      if (refresh) {
        window.history.pushState({}, document.title, window.location.pathname);
        location.reload();
      }
    })
    .catch((err) => {
      if (err?.response?.data?.message) {
        Vue.$toast.error(err.response.data.message);
      }
    });
}

export default i18n;

<template>
  <div class="content-wrapper">
    <section id="account-login" class="flexbox-container">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <!-- image -->
        <div class="col-xl-3 col-lg-4 col-md-5 col-sm-5 col-12 p-0 text-center d-none d-md-block">
          <div class="border-grey border-lighten-3 m-0 box-shadow-0 card-account-left height-400">
            <router-link to="/">
              <img src="../assets/images/logo/logo.202104212035.png" class="card-account-img" alt="card-account-img" />
            </router-link>
          </div>
        </div>
        <!-- login form -->
        <div class="col-xl-3 col-lg-4 col-md-5 col-sm-5 col-12 p-0">
          <div class="card border-grey border-lighten-3 m-0 box-shadow-0 card-account-right">
            <div class="card-content">
              <div class="card-body p-md-3">
                <div class="d-sm-block d-md-none text-center pb-1">
                  <router-link to="/">
                    <img
                      class="brand-logo"
                      alt="Miracle Cash&More"
                      src="../assets/images/logo/logo-sm.202104212035.jpg"
                    />
                  </router-link>
                </div>
                <p class="text-center h5 text-capitalize">{{ $t('passwordReset-title') }}</p>
                <p class="mb-3 text-center">{{ $t('passwordReset-desc') }}</p>
                <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                  <form class="form-horizontal form-signin" @submit.prevent="handleSubmit(resetPasswordSubmit)">
                    <validation-provider
                      rules="required"
                      :name="$t('register-individualEmail')"
                      v-slot="{ classes, errors }"
                    >
                      <fieldset class="form-label-group">
                        <input
                          type="mail"
                          class="form-control"
                          id="user-email"
                          v-model="resetPassword.email"
                          :placeholder="$t('passwordReset-email')"
                          :class="classes"
                        />
                        <label for="user-email">{{ $t('passwordReset-email') }}</label>
                        <span class="error__message">{{ errors[0] }}</span>
                      </fieldset>
                    </validation-provider>
                    <validation-provider
                      rules="required|min:8|strongPassword"
                      :name="$t('register-individualPassword')"
                      v-slot="{ classes, errors }"
                      class="col-12 col-md-6"
                    >
                      <fieldset class="form-label-group">
                        <i
                          class="showPassword"
                          @click="switchPasswordType()"
                          v-bind:class="passwordFieldType === 'password' ? 'passwordEyeOff' : 'passwordEye'"
                        ></i>
                        <input
                          :type="passwordFieldType"
                          class="form-control"
                          id="password"
                          v-model="resetPassword.password"
                          :placeholder="$t('passwordReset-password')"
                          :class="classes"
                        />
                        <label for="password">{{ $t('passwordReset-password') }}</label>
                        <span class="error__message">{{ errors[0] }}</span>
                      </fieldset>
                    </validation-provider>
                    <validation-provider
                      :rules="'required|min:8|password:@' + $t('register-individualPassword')"
                      :name="$t('register-individualPasswordRepeat')"
                      v-slot="{ classes, errors }"
                      class="col-12 col-md-6"
                    >
                      <fieldset class="form-label-group">
                        <input
                          type="password"
                          class="form-control"
                          id="confirm-password"
                          v-model="resetPassword.password_confirmation"
                          :placeholder="$t('passwordReset-passwordRepeat')"
                          :class="classes"
                        />
                        <label for="confirm-password">{{ $t('passwordReset-passwordRepeat') }}</label>
                        <span class="error__message">{{ errors[0] }}</span>
                      </fieldset>
                    </validation-provider>

                    <button type="submit" class="btn-gradient-primary btn-block my-1">
                      {{ $t('login-btnPasswordReset') }}
                    </button>
                  </form>
                </ValidationObserver>
              </div>
              <div class="form-group row">
                <language />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import i18n from '../config/_i18n';

import Language from '@/components/global/Language';

import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import MessagesTR from 'vee-validate/dist/locale/tr.json';
import MessagesEN from 'vee-validate/dist/locale/en.json';
import MessagesNL from 'vee-validate/dist/locale/nl.json';
import MessagesME from 'vee-validate/dist/locale/en.json';
import MessagesET from 'vee-validate/dist/locale/et.json';
import MessagesDE from 'vee-validate/dist/locale/de.json';
import MessagesFR from 'vee-validate/dist/locale/fr.json';

let Messages, PasswordErrorMessage, StrongPasswordErrorMessage;
if (i18n.locale === 'tr') {
  Messages = MessagesTR;
  PasswordErrorMessage = 'Parola  eşleşmiyor';
  StrongPasswordErrorMessage =
    'Parolanız en az bir küçük harf, bir büyük harf ve bir rakam içermelidir. Özel karakterler de kullanabilirsiniz';
} else if (i18n.locale === 'nl') {
  Messages = MessagesNL;
  PasswordErrorMessage = 'Password confirmation does not match';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
} else if (i18n.locale === 'me') {
  Messages = MessagesME;
  PasswordErrorMessage = 'Password confirmation does not match';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
} else if (i18n.locale === 'et') {
  Messages = MessagesET;
  PasswordErrorMessage = 'Password confirmation does not match';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
} else if (i18n.locale === 'de') {
  Messages = MessagesDE;
  PasswordErrorMessage = 'Password confirmation does not match';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
} else if (i18n.locale === 'fr') {
  Messages = MessagesFR;
  PasswordErrorMessage = 'Password confirmation does not match';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
} else {
  Messages = MessagesEN;
  PasswordErrorMessage = 'Password confirmation does not match';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
}

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule],
    message: Messages.messages[rule]
  });
});

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: PasswordErrorMessage
});
extend('strongPassword', {
  validate(value) {
    const strongRegex = new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).+$');
    return strongRegex.test(value);
  },
  message: StrongPasswordErrorMessage
});

export default {
  name: 'PasswordReset',
  components: { Language, ValidationProvider, ValidationObserver },
  data() {
    return {
      loginType: 'personal',
      passwordFieldType: 'password'
    };
  },
  methods: {
    ...mapActions('customer', ['POST_RESET_PASSWORD']),
    resetPasswordSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        const token = this.getParameterByName('token');
        this.POST_RESET_PASSWORD(token);
      });
    },
    getParameterByName(name, url = window.location.href) {
      // eslint-disable-next-line no-useless-escape
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    switchPasswordType() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    }
  },
  computed: {
    ...mapState({
      resetPassword: (state) => state.customer.resetPassword,
      isLoading: (state) => state.customer.isLoading
    })
  }
};
</script>

<template>
  <div class="content-wrapper">
    <section id="account-login" class="flexbox-container">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <!-- image -->
        <div class="col-xl-3 col-lg-4 col-md-5 col-sm-5 col-12 p-0 text-center d-none d-md-block">
          <div class="border-grey border-lighten-3 m-0 box-shadow-0 card-account-left height-400">
            <router-link to="/">
              <img src="../assets/images/logo/logo.202104212035.png" class="card-account-img" alt="card-account-img" />
            </router-link>
          </div>
        </div>
        <!-- login form -->
        <div class="col-xl-4 col-lg-5 col-md-5 col-sm-5 col-12 p-0">
          <div class="card border-grey border-lighten-3 m-0 box-shadow-0 card-account-right">
            <div class="card-content">
              <div class="card-body p-md-3">
                <div class="d-sm-block d-md-none text-center pb-1">
                  <router-link to="/">
                    <img
                      class="brand-logo"
                      alt="Miracle Cash&More"
                      src="../assets/images/logo/logo-sm.202104212035.jpg"
                    />
                  </router-link>
                </div>
                <p class="text-center h5 text-capitalize">{{ $t('login-titlePasswordReset') }}</p>
                <p class="mb-3 text-center" v-html="$t('login-resetPasswordDesc')"></p>
                <ValidationObserver ref="form">
                  <form class="form-horizontal form-signin" @submit.prevent="forgotPasswordBtn">
                    <fieldset>
                      <div class="input-group">
                        <multiselect
                          v-model="$store.state.customer.phoneCode"
                          :placeholder="$t('login-phone-code')"
                          label="title"
                          track-by="title"
                          :options="countries"
                          :option-height="104"
                          :custom-label="customLabel"
                          :allow-empty="false"
                          :show-labels="false"
                          class="phoneCode"
                        >
                          <template slot="singleLabel" slot-scope="props">
                            <div class="option">
                              <img class="option__image" :src="props.option.img" :alt="props.option.title" />
                              <span class="option__title">{{ props.option.code }}</span>
                            </div>
                          </template>
                          <template slot="option" slot-scope="props">
                            <div class="option">
                              <img class="option__image" :src="props.option.img" :alt="props.option.title" />
                              <span class="option__title">{{ props.option.code }}</span>
                            </div>
                          </template>
                          <template slot="noOptions">{{ $t('noOptions') }}</template>
                        </multiselect>
                        <validation-provider
                          :name="$t('login-phone')"
                          rules="required"
                          v-slot="{ classes, errors }"
                          class="form-label-group -wd"
                        >
                          <the-mask
                            class="form-control"
                            :class="classes"
                            type="tel"
                            id="phone"
                            v-model="forgotPassword.phone"
                            :placeholder="$t('login-phone')"
                            :mask="[...$store.state.customer.phoneCode.phoneMask]"
                          />
                          <label for="phone">{{ $t('login-phone') }}</label>
                          <span class="error__message">{{ errors[0] }}</span>
                        </validation-provider>
                      </div>
                    </fieldset>
                    <validation-provider name="Email" rules="required" v-slot="{ classes, errors }">
                      <fieldset class="form-label-group">
                        <input
                          type="email"
                          class="form-control"
                          :class="classes"
                          id="user-email"
                          v-model="forgotPassword.email"
                          placeholder="Email"
                          value=""
                          required=""
                          autofocus=""
                        />
                        <label for="user-email">Email</label>
                        <span class="error__message">{{ errors[0] }}</span>
                      </fieldset>
                    </validation-provider>
                    <button type="button" @click="forgotPasswordBtn" class="btn-gradient-primary btn-block my-1">
                      {{ $t('login-titlePasswordReset') }}
                    </button>
                  </form>
                </ValidationObserver>
              </div>
              <div class="form-group row">
                <language />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import i18n from '../config/_i18n';

import Multiselect from 'vue-multiselect';

import Language from '@/components/global/Language';

import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import MessagesTR from 'vee-validate/dist/locale/tr.json';
import MessagesEN from 'vee-validate/dist/locale/en.json';
import MessagesNL from 'vee-validate/dist/locale/nl.json';
import MessagesME from 'vee-validate/dist/locale/en.json';
import MessagesET from 'vee-validate/dist/locale/et.json';
import MessagesDE from 'vee-validate/dist/locale/de.json';
import MessagesFR from 'vee-validate/dist/locale/fr.json';

let Messages;
if (i18n.locale === 'tr') {
  Messages = MessagesTR;
} else if (i18n.locale === 'nl') {
  Messages = MessagesNL;
} else if (i18n.locale === 'me') {
  Messages = MessagesME;
} else if (i18n.locale === 'et') {
  Messages = MessagesET;
} else if (i18n.locale === 'de') {
  Messages = MessagesDE;
} else if (i18n.locale === 'fr') {
  Messages = MessagesFR;
} else {
  Messages = MessagesEN;
}
Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule],
    message: Messages.messages[rule]
  });
});

export default {
  name: 'ForgotPassword',
  components: {
    Multiselect,
    ValidationObserver,
    ValidationProvider,
    Language
  },
  data() {
    return {
      minPhoneCount: 7
    };
  },
  methods: {
    ...mapActions('customer', ['POST_FORGOT_PASSWORD', 'GET_COUNTRIES']),
    forgotPasswordBtn() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.POST_FORGOT_PASSWORD();
      });
    },
    customLabel({ title, desc }) {
      return `${title} – ${desc}`;
    }
  },
  computed: {
    ...mapState({
      forgotPassword: (state) => state.customer.forgotPassword,
      isLoading: (state) => state.customer.isLoading,
      options: (state) => state.customer.options,
      countries: (state) => state.customer.countries
    })
  },
  created() {
    this.GET_COUNTRIES();
  },
  beforeCreate() {
    document.querySelector('body').classList.remove('fixed-navbar');
  },
  watch: {
    phoneCode: {
      handler() {
        this.login.phone = '';
        const arrayList = this.phoneCode.phoneMask;
        let countArray = [];
        arrayList.forEach(function (elem) {
          countArray.push(elem.split('#').length - 1);
        });
        this.minPhoneCount = Math.min(...countArray) - 1;
      }
    }
  }
};
</script>

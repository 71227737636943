import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import i18n from './config/_i18n';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import VueTheMask from 'vue-the-mask';
import VueCurrencyInput from 'vue-currency-input';
import VueIntercom from 'vue-intercom';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import { configure } from 'vee-validate';

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid',
    dirty: ['is-dirty', 'is-dirty']
  }
});

Vue.use(VueTheMask);

const vueCurrencyInputOptions = { globalOptions: { precision: 2, currency: false } };
Vue.use(VueCurrencyInput, vueCurrencyInputOptions);

Vue.use(VueIntercom, { appId: 'r54ssgxh' });

Vue.use(Toast, {
  transition: 'Vue-Toastification__fade',
  maxToasts: 20,
  newestOnTop: true
});

Vue.use(VueSweetalert2);

Vue.directive('select-overflow', {
  inserted: (el, _binding, vnode) => {
    let originalWidth;
    let originalPosition;
    let originalZIndex;
    let selectIsOpen = false;
    vnode.child.$watch('isOpen', isOpen => {
      selectIsOpen = isOpen;
      if (isOpen) {
        const { offsetWidth } = el;
        originalWidth = el.style.width;
        originalPosition = el.style.position;
        originalZIndex = el.style.zIndex;
        el.style.width = `${offsetWidth}px`;
        el.style.position = 'fixed';
        el.style.zIndex = 2;
      } else {
        el.style.position = originalPosition;
        el.style.width = originalWidth;
        el.style.zIndex = originalZIndex;
      }
    });

    window.addEventListener('wheel', event => {
      if (selectIsOpen) {
        // disabled outside scroll when select is open
        event.stopPropagation();
      }
    }, true);
  }
});

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  i18n,
  watch: {
    $route: (to) => {
      window.Intercom('update');
      window.Intercom('trackEvent', to.name);
    }
  },
  mounted() {
    this.$intercom.boot({ appId: 'r54ssgxh', language_override: i18n.locale === 'tr' ? 'tr' : 'en' });
  },
  render: h => h(App)
}).$mount('#app');

<template>
  <div class="content-wrapper">
    <section id="account-login" :class="'flexbox-container ' + activeTab">
      <div class="col-12 d-flex align-items-center justify-content-center">
        <!-- image -->
        <div class="col-xl-3 col-lg-4 col-md-3 col-sm-3 col-12 p-0 text-center d-none d-md-block">
          <div class="border-grey border-lighten-3 m-0 box-shadow-0 card-account-left height-400">
            <router-link to="/">
              <img src="../assets/images/logo/logo-sm.20211204-2.png" class="card-account-img" alt="card-account-img" />
            </router-link>
          </div>
        </div>
        <!-- login form -->
        <div class="col-xl-6 col-lg-6 col-md-9 col-sm-9 col-12 p-0">
          <div class="card border-grey border-lighten-3 m-0 box-shadow-0 card-account-right">
            <div class="card-content">
              <div class="card-body p-md-3">
                <div class="d-sm-block d-md-none text-center pb-1">
                  <router-link to="/">
                    <img
                      class="brand-logo"
                      alt="Miracle Cash&More"
                      src="../assets/images/logo/logo-sm.202104212035.jpg"
                    />
                  </router-link>
                </div>
                <p class="text-center h5 text-capitalize">{{ $t('login-welcome') }}</p>
                <p class="mb-3 text-center">
                  {{
                    activeTab === 'personal'
                      ? $t('register-titleIndividualRegister')
                      : $t('register-btnCorporateRegister')
                  }}
                </p>
                <ul class="nav nav-tabs row">
                  <li class="nav-item col-6">
                    <a class="nav-link active" data-toggle="tab" href="#personal" @click="activeTab = 'personal'">
                      {{ $t('register-titleIndividualRegister') }}
                    </a>
                  </li>
                  <li class="nav-item col-6">
                    <a class="nav-link ml-0" data-toggle="tab" href="#corporate" @click="activeTab = 'corporate'">
                      {{ $t('register-btnCorporateRegister') }}
                    </a>
                  </li>
                </ul>
                <div class="tab-content tab-content-default">
                  <div class="tab-pane fade active show" id="personal" role="tabpanel">
                    <Personal v-bind:static-content="staticContent" />
                  </div>
                  <div class="tab-pane fade" id="corporate">
                    <Corporate v-bind:static-content="staticContent" />
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <language />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Personal from '@/components/register/Personal.vue';
import Corporate from '@/components/register/Corporate.vue';
import Language from '@/components/global/Language';
import Services from '../config/_axios';
import API from '../api';
import Vue from 'vue';

export default {
  name: 'Register',
  components: {
    Personal,
    Corporate,
    Language
  },
  data() {
    return {
      activeTab: 'personal',
      staticContent: {}
    };
  },
  created() {
    this.getContent();
  },
  methods: {
    getContent() {
      Services.get(API.userAgreementContent)
        .then((res) => {
          this.staticContent = res.data.data;
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.flexbox-container.personal {
  height: auto !important;
  padding-top: 70px;
}
.card-account-img {
  max-width: 200px;
  width: 100% !important;
  padding-right: 20px;
}

.flexbox-container {
  &.corporate {
    height: auto;
    padding-top: 70px;
  }
}
</style>

<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form method="post" @submit.prevent="handleSubmit(buyBtn)">
        <div class="form-group">
          <label class="mr-sm-2">{{ $t('instanttrade-label-cryptotype') }}</label>
          <div class="row">
            <validation-provider rules="required" v-slot="{ classes, errors }" class="col-12">
              <multiselect
                v-model="quicksale.crypto"
                @input="getCoinSelection"
                deselect-label=""
                select-label=""
                selected-label=""
                track-by="name"
                label="name"
                placeholder="Select one"
                :options="getFilteredCrypto"
                :searchable="false"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
                <template slot="option" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
                <template slot="noOptions">{{ $t('noOptions') }}</template>
              </multiselect>
              <span class="error__message">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>
        <div class="form-group">
          <label class="mr-sm-2">{{ $t('instanttrade-label-currenciestype') }}</label>
          <div class="row">
            <validation-provider rules="required" v-slot="{ classes, errors }" class="col-12">
              <multiselect
                v-model="quicksale.currency"
                @input="getCoinSelection"
                deselect-label=""
                select-label=""
                selected-label=""
                track-by="name"
                label="name"
                placeholder="Select one"
                :options="getFilteredCurrency"
                :searchable="false"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
                <template slot="option" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
                <template slot="noOptions">{{ $t('noOptions') }}</template>
              </multiselect>
              <span class="error__message">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </div>
        <div class="form-group">
          <label class="mr-sm-2">{{ $t('instanttrade-enterTotal') }}</label>
          <div class="row" v-if="quicksale.type == 10">
            <validation-provider
              rules="required"
              :name="$t('instanttrade-label-amount')"
              v-slot="{ classes, errors }"
              class="col-6"
            >
              <currency-input
                id="currency_amount"
                class="form-control"
                :class="classes"
                v-model="quicksale.total"
                :precision="quicksaleCurrenyIsCrypto"
                :placeholder="$t('instanttrade-label-amount')"
                @keyup="getCalculate('total')"
                @blur="clearCalculateForm"
              />
              <span class="error__message">{{ errors[0] }}</span>
            </validation-provider>
            <validation-provider
              rules="required"
              :name="$t('instanttrade-label-quantity')"
              v-slot="{ classes, errors }"
              class="col-6"
            >
              <currency-input
                id="usd_amount"
                class="form-control"
                :class="classes"
                :precision="8"
                v-model="quicksale.amount"
                :placeholder="$t('instanttrade-label-quantity')"
                @keyup="getCalculate('amount')"
                @blur="clearCalculateForm"
              />
              <span class="error__message">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="d-flex justify-content-between mt-1">
            <p class="mb-0">{{ $t('instanttrade-availablelimit') }}</p>
            <h6 class="mb-0" v-if="quicksale.currency">{{ getSelectedCurrency(quicksale.currency.code)[0] }}</h6>
          </div>
          <div class="d-flex justify-content-between mt-1">
            <p class="mb-0">{{ $t('instanttrade-buyPrice') }}</p>
            <h6 class="mb-0">{{ buyNow.priceSell }}</h6>
          </div>
        </div>
        <div class="col-12 text-center mt-2">
          <button type="submit" class="btn-gradient-success btn-block">{{ $t('instanttrade-btn-buynow') }}</button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions, mapGetters } from 'vuex';
import i18n from '../../config/_i18n';

import Multiselect from 'vue-multiselect';

import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import MessagesTR from 'vee-validate/dist/locale/tr.json';
import MessagesEN from 'vee-validate/dist/locale/en.json';
import MessagesNL from 'vee-validate/dist/locale/nl.json';
import MessagesME from 'vee-validate/dist/locale/en.json';
import MessagesET from 'vee-validate/dist/locale/et.json';
import MessagesDE from 'vee-validate/dist/locale/de.json';
import MessagesFR from 'vee-validate/dist/locale/fr.json';

let Messages;
if (i18n.locale === 'tr') {
  Messages = MessagesTR;
} else if (i18n.locale === 'nl') {
  Messages = MessagesNL;
} else if (i18n.locale === 'me') {
  Messages = MessagesME;
} else if (i18n.locale === 'et') {
  Messages = MessagesET;
} else if (i18n.locale === 'de') {
  Messages = MessagesDE;
} else if (i18n.locale === 'fr') {
  Messages = MessagesFR;
} else {
  Messages = MessagesEN;
}
Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule],
    message: Messages
  });
});

export default {
  name: 'BuyNow',
  components: {
    Multiselect,
    ValidationObserver,
    ValidationProvider
  },
  methods: {
    ...mapActions('global', ['GET_CURRENCY', 'GET_HISTORY']),
    ...mapActions('quicksale', [
      'POST_CALCULATE',
      'POST_BUY',
      'GET_TRY_BALANCE',
      'GET_BUY_NOW_PRICES',
      'GET_CURRENCY_BALANCE',
      'GET_SELL_NOW_PRICES',
      'GET_STATIC_CONTENT_BUY'
    ]),
    buyBtn() {
      if (this.quicksale.total < this.quicksale.raw.currency.min_pay / 100) {
        Vue.$toast.error(this.$t('instanttrade-buy-invalidAmount', { amount: this.quicksale.raw.min_pay_formatted }));
        return;
      }
      this.$store
        .dispatch('quicksale/POST_BUY')
        .then(() => {
          this.$refs.form.reset();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCalculate(calcType) {
      if (this.quicksale.amount === null && this.quicksale.total === null) {
        return;
      } else if (calcType === 'total' && this.quicksale.total === null) {
        this.quicksale.amount = null;
        return;
      } else if (calcType === 'amount' && this.quicksale.amount === null) {
        this.quicksale.total = null;
        return;
      }
      this.POST_CALCULATE({ buy: 10, calcType });
    },
    getCoinSelection() {
      this.$store.commit('quicksale/CLEAR_FORM');
      if (this.quicksale.currency && this.quicksale.crypto) {
        this.GET_BUY_NOW_PRICES();
        this.GET_CURRENCY_BALANCE();
        this.GET_SELL_NOW_PRICES();
        this.GET_HISTORY({ currency: this.quicksale.crypto.code.toLowerCase() });
      }
      this.$refs.form.reset();
    },
    clearCalculateForm() {
      this.$refs.form.reset();
    }
  },
  computed: {
    ...mapState({
      quicksale: (state) => state.quicksale.quicksale,
      currency: (state) => state.global.currency,
      crypto: (state) => state.global.crypto,
      buyNow: (state) => state.quicksale.buyNow
    }),
    ...mapGetters('global', ['getCrypto', 'getCurrency']),
    ...mapGetters('wallet', ['getSelectedCurrency']),
    getFilteredCrypto: function () {
      const vm = this;
      return this.getCrypto.filter(function (item) {
        return item.id != vm?.quicksale?.currency?.id;
      });
    },
    getFilteredCurrency: function () {
      const vm = this;
      return this.getCurrency.filter(function (item) {
        return item.id != vm?.quicksale?.crypto?.id && item.quicksale === true;
      });
    },

    quicksaleCurrenyIsCrypto() {
      return this.quicksale?.currency?.crypto ? 8 : 2;
    }
  },
  watch: {
    getCurrency: function (val) {
      if (this.quicksale.currency === null) {
        this.quicksale.currency = val[0];
        this.getCoinSelection();
      }
    },
    getCrypto: function (val) {
      if (this.quicksale.crypto === null) {
        this.quicksale.crypto = val[0];
        this.getCoinSelection();
      }
    }
  },
  created() {
    this.GET_CURRENCY();
    //this.GET_TRY_BALANCE();
    //this.GET_BUY_NOW_PRICES();
    this.GET_STATIC_CONTENT_BUY();
  }
};
</script>

<style scoped></style>

<template>
  <div class="main-menu menu-fixed menu-dark menu-bg-default rounded menu-accordion menu-shadow">
    <div class="main-menu-content">
      <router-link @click.native="toggleMenu" class="navigation-brand d-none d-md-block d-lg-block d-xl-block" to="/">
        <img class="brand-logo" alt="Miracle Cash&More" src="../../assets/images/logo/logo.202104212035-white.png" />
      </router-link>
      <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
        <!--
        <li class="nav-item">
          <router-link to="/"
            ><i class="icon-grid"></i
            ><span class="menu-title" data-i18n="">Dashboard</span></router-link
          >
        </li>
        -->
        <li class="nav-item">
          <router-link @click.native="toggleMenu" to="/instant-trade">
            <i class="icon-fire"></i>
            <span class="menu-title" data-i18n="">{{ $t('top-menu-buyNow') }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link @click.native="toggleMenu" to="/wallet">
            <i class="icon-wallet"></i>
            <span class="menu-title" data-i18n="">{{ $t('top-menu-wallet') }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link @click.native="toggleMenu" to="/deposit">
            <i class="icon-diamond"></i>
            <span class="menu-title" data-i18n="">{{ $t('top-menu-deposit') }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link @click.native="toggleMenu" to="/withdrawal">
            <i class="icon-arrow-down"></i>
            <span class="menu-title" data-i18n="">{{ $t('top-menu-withdrawal') }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link @click.native="toggleMenu" to="/deal">
            <i class="icon-tag"></i>
            <span class="menu-title" data-i18n="">{{ $t('miracle_deal.title') }}</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link @click.native="toggleMenu" to="/personal/account-settings">
            <i class="icon-user-following"></i>
            <span class="menu-title" data-i18n="">{{ $t('top-menu-account') }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeftMenu',
  methods: {
    toggleMenu() {
      if (window.innerWidth <= 768) {
        window.$.app.menu.toggle();
      }
    }
  }
};
</script>

<style lang="scss">
@media only screen and (max-width: 768px) {
  .vertical-overlay-menu .main-menu .navigation > li > a > i {
    float: none;
    font-size: 2.2rem;
  }
  .vertical-compact-menu .main-menu .navigation > li > a > span {
    visibility: visible;
    width: 100%;
  }
  body.menu-hide .main-menu {
    display: none;
  }
}
</style>

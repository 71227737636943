<template>
  <div class='content-wrapper'>
    <Breadcrumb :title="$t('header-menu-help-withdraw')" />
    <div class='row'>
      <div class='col-xl-6 col-lg-6 col-md-12'>
        <div class='card pull-up-only-shadow'>
          <div class='card-body'>
            <WithdrawalForm />
          </div>
        </div>
      </div>
      <div class='col-xl-6 col-lg-6 col-md-12'>
        <Information />
      </div>
    </div>
    <AddWalletModal v-if='showAddWalletModal' ref='showAddWalletModal'
                    :title="selectedCurrency.crypto ? $t('withdrawal-addCryptoWalletModal') : $t('withdrawal-addAccountModal')" />
    <History v-if='selectedCurrency' />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import WithdrawalForm from '@/components/withdrawal/Form.vue';
import History from '@/components/withdrawal/History.vue';
import Information from '@/components/withdrawal/Information.vue';
import Breadcrumb from '@/components/layout/Breadcrumb';
import AddWalletModal from '@/components/withdrawal/Add';

export default {
  name: 'Withdrawal',
  computed: {
    ...mapState({
      showAddWalletModal: state => state.withdrawal.showAddWalletModal
    }),
    selectedCurrency: {
      get() {
        return this.$store.state.withdrawal.selectedCurrency;
      }
    }
  },
  components: {
    WithdrawalForm,
    History,
    Information,
    Breadcrumb,
    AddWalletModal
  }
};
</script>

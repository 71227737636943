import Vue from 'vue';
const moment = require('moment');

import API from '@/api';
import Services from '@/config/_axios';
import { getSuccessServiceMsg, getErrorServiceMsg } from '@/config/serviceMessages';
import router from "@/router";




const striker = {
  state: () => ({
    strikerList: [],
    strikerMyList: [],
    skmatch: [],
    selectedStrikerId: '',
    isLoading: true
  }),
  mutations: {
    SET_STRIKER(state, payload) {
      state.strikerList=[];
      for (let key in payload) {
        let object = payload[key];
        var actionTime = moment(object.signDate?.date, "YYYY-MM-DD HH:mm:ssZ");
        var timeAgo = actionTime.fromNow();
        state.strikerList.push(Object.assign({ time_ago: timeAgo }, payload[key]))
      }
    },
    SET_MY_STRIKER(state, payload) {
      state.strikerMyList=[];
      for (let key in payload) {
        let object = payload[key];
        var actionTime = moment(object.signDate?.date, "YYYY-MM-DD HH:mm:ssZ");
        var timeAgo = actionTime.fromNow();
        state.strikerMyList.push(Object.assign({ time_ago: timeAgo }, payload[key]))
      }
    },
    SET_SKMATCH(state, payload) {
      state.skmatch = [];
      for (let key in payload) {
        let object = payload[key];
        var actionTime = moment(object.end_date?.date, "YYYY-MM-DD HH:mm:ssZ");
        var timeAgo = actionTime.fromNow();
        state.skmatch.push(Object.assign({ time_ago: timeAgo }, payload[key]))
      }
    },
    SET_STRIKER_ID(state, payload) {
        state.selectedStrikerId = payload;
    },
    IS_LOADING(state, payload) {
        state.isLoading = payload;
    }
  },
  actions: {
    async GET_STRIKER({ commit }, params) {
      commit('IS_LOADING', true);
      await Services.get(API.striker, params)
        .then(res => {
          commit('SET_STRIKER', res.data.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          console.log("err: ",err)
          if (err?.response)  Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    async GET_MY_STRIKER({ commit }, params) {
      commit('IS_LOADING', true);
      await Services.get(API.strikerMy, params)
        .then(res => {
          commit('SET_MY_STRIKER', res.data.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          console.log("err: ",err)
          if (err?.response)  Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    async GET_STRIKER_TV({ commit }) {
      await Services.get(`${API.striker}?limit=10&order=DESC`)
        .then(res => {
          commit('SET_STRIKER', res.data.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          console.log("err: ",err)
          if (err?.response)  Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    async GET_LAST_TRANSACTION({ commit }, params) {
      commit('IS_LOADING', true);
      await Services.get(API.skmatch, params)
        .then(res => {
          commit('SET_SKMATCH', res.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          console.log("err: ",err)
          commit('IS_LOADING', false);
          if (err?.response)  Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    async POST_STRIKER({ commit }, params) {
      commit('IS_LOADING', true);
      try {

        const res = await Services.get(API.striker_order,{
          params
        });
        console.log("POST_STRIKER: ",res)
        if(res.data.success) Vue.$toast.success(getSuccessServiceMsg(res));
        else Vue.$toast.error(getErrorServiceMsg(res));
      } catch (err) {
        console.log("err: ",err)
        if (err?.response)  Vue.$toast.error(getErrorServiceMsg(err.response));
      } finally {
        commit('IS_LOADING', false);
      }
    },
    POST_STRIKER_NEW({ commit }, params) {
      commit('IS_LOADING', true);
      Services.post(API.striker_new, params)
        .then(res => {
          console.log("POST_STRIKER_NEW: ",res)
          commit('IS_LOADING', false);
          commit('keeper/statusModal', false, { root: true });
          if(res.data.success) {
            Vue.$toast.success(getSuccessServiceMsg(res));
            this.dispatch('keeper/GET_KEEPER', {root: true});
          }
          else Vue.$toast.error(getErrorServiceMsg(res));
        })
        .catch(err => {
          console.log("err: ",err)
          if (err?.response)  Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    DELETE_STRIKER({ commit }, id) {
      const data = {
        striker_id: id
      };
      Services.post(`${API.striker_rollback}`, data)
        .then(res => {
          console.log("DELETE_STRIKER: ", res)
          commit('IS_LOADING', false);
          if(res.data.success) {
            Vue.$toast.success(getSuccessServiceMsg(res));
            this.dispatch('striker/GET_MY_STRIKER', {root: true});
          }
        else Vue.$toast.error(getErrorServiceMsg(res));

        })
        .catch(err => {
          if (err?.response)  Vue.$toast.error(getErrorServiceMsg(err.response));
        });
    },
    TRANSFER({commit}, id) {
      const data = {
        striker_id: id
      };
      Services.post(`${API.striker_transfer}`, data)
          .then(res => {
            console.log("TRANSFER: ", res)
            commit('IS_LOADING', false);
            if (res.data.success == true) {
              Vue.$toast.success(getSuccessServiceMsg(res));
              router.push({ name: 'StrikerMyList' });
              this.dispatch('keeper/GET_MY_STRIKER', {root: true});
            } else Vue.$toast.error(getErrorServiceMsg(res));
          })
          .catch(err => {
            if (err?.response) Vue.$toast.error(getErrorServiceMsg(err.response));
          });
    },
  },
  namespaced: true
}

export default striker;

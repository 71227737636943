<template>
  <div class='bd-content p-4'>
    <template v-if='!staticContent.content'>
      <content-loader
        :width='1000'
        :height='63'
        :speed='2'
        primaryColor='#f4f5fa'
        secondaryColor='#eee'
        class='loading-bg'
        v-for='(item, index) in 5'
        :key="'loading-' + index"
      >
        <rect x='0' y='0' rx='6' ry='6' width='1000' height='83' />
      </content-loader>
    </template>
    <h3 class='h3'>{{ staticContent.title }}</h3>
    <p v-for='(item, index) in staticContent.content' :key="'content-' + index" v-html='item'></p>
    <img v-if='staticContent.content' src='../../assets/images/logo/prime-trust-logo.png' alt='' />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ContentLoader } from 'vue-content-loader';
import Services from '../../config/_axios';
import Vue from 'vue';
import API from '../../api';

export default {
  name: 'Information',
  components: {
    ContentLoader
  },
  data() {
    return {
      staticContent: {}
    };
  },
  computed: {
    ...mapState({
      selectedCurrency: state => state.withdrawal.selectedCurrency
    }),
  },
  watch: {
    selectedCurrency: function(val) {
      this.getContent(val.code);
    }
  },
  created() {
    this.getContent(this.selectedCurrency?.code || 'TRY');
  },
  methods: {
    getContent(code) {
      Services.get(`${API.withdrawContent}?currency=${code}`)
        .then(res => {
          this.staticContent = res.data.data;
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    }
  }
};
</script>

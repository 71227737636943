const api = {
  localization: 'localization/',

  currencies: '/common/currencies',
  banks: '/common/banks',
  bankList: 'common/banks',
  countries: '/common/countries',
  cities: '/common/cities/',
  towns: '/common/towns/',
  taxbranches: '/common/taxbranches/',
  userlevels: 'common/contents/userlevels',

  login: '/auth/login',
  loginOtp: '/auth/login/2fa',
  register: '/auth/register',
  logout: '/auth/logout',
  emailverify: '/auth/verification/email',
  passwordRecovery: '/auth/password',
  passwordReset: '/auth/password/reset',
  passwordResetSms: '/auth/password/reset/sms',
  sendCode: '/auth/send-code',
  refreshToken: '/auth/token/refresh',
  userAgreementContent: '/common/contents/user-agreement',

  loginCorporate: '/corporate',

  user: '/user/me',
  tryBalance: '/user/balances/try',
  currencyBalance: '/user/balances/',
  transactions: '/user/histories/transactions',
  actions: '/user/histories/actions',
  notifications: '/user/settings/notifications',
  updatePassword: '/user/update/password',
  updateLanguage: '/user/update/language',
  userNotifications: 'user/notifications',
  readNotifications: 'user/notifications/read',
  emailOtp: 'user/security/2fa/email',
  smsOtp: 'user/security/2fa/sms',
  authenticatorOtp: 'user/security/2fa/authenticator',
  countryCodes: 'user/verification/getCountryCodesForData',
  formToCountry: 'user/verification/getFieldsAndConsents?CountryCode=',
  upgradeTwoNdLevel: 'user/verification/verifyData?CountryCode=',
  documentsToCountry: 'user/verification/getCountryCodesForDocument',
  documentTypes: 'user/verification/getDocumentTypes?CountryCode=',
  verifyDocument: 'user/verification/verifyDocument',

  history: '/finance/deposit/histories/try',
  createNonexistentWallet: 'finance/deposit/assign/',
  createNonexistentWalletMulti: 'finance/deposit/assign/multi',

  calculate: '/finance/quicksale/btc/calculate',

  buyAndSellNowPrices: '/finance/quicksale/',
  buy: '/finance/quicksale/buy',
  sell: '/finance/quicksale/sell',
  quicksaleBuyContent: '/common/contents/quicksale',
  quicksaleSellContent: '/common/contents/quicksale-sell',

  withdraw: '/finance/withdraw/wallets',
  providers: '/finance/withdraw/providers/',
  withdrawCreate: 'finance/withdraw/create',
  createWallet: 'finance/withdraw/wallets/create',
  bankAccountList: 'finance/withdraw/wallets/bank_transfer/',
  withdrawHistory: '/finance/withdraw/histories/',
  withdrawContent: '/common/contents/withdraw',

  depositProviders: '/finance/deposit/providers',
  depositCreate: 'finance/deposit/create',
  depositApprove: 'finance/deposit/approved/',
  depositHistory: 'finance/deposit/histories/',
  depositContent: '/common/contents/deposit',


  striker: "/miracle-deal/get-striker-order-list",
  strikerMy: "/miracle-deal/get-my-striker-order-list",
  keeper: "/miracle-deal/get-keeper-order-list",
  keeperMy: "/miracle-deal/get-my-keeper-order-list",
  keeper_new: "/miracle-deal/new-keeper-order",
  keeper_order: "/miracle-deal/new-keeper-order",
  keeper_rollback: "/miracle-deal/delete-my-keeper-order",
  keeper_transfer: "/miracle-deal/new-match-keeper-order",
  striker_new: "/miracle-deal/new-striker-order",
  striker_order: "/miracle-deal/new-striker-order",
  striker_calculate: "/miracle-deal/striker/calculate",
  striker_rollback: "/miracle-deal/delete-my-striker-order",
  striker_transfer: "/miracle-deal/new-match-striker-order",

};

export default api;

<template>
  <div>
    <div class="form-group">
      <label class="mr-sm-2">{{ $t('deposit-title') }}</label>
      <div class="input-group">
        <multiselect
          v-model="selectedCurrency"
          @input="getCoinSelection"
          deselect-label=""
          select-label=""
          selected-label=""
          track-by="name"
          label="name"
          placeholder="Select one"
          :options="depositCurrency"
          :searchable="false"
          :allow-empty="false"
        >
          <template slot="singleLabel" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
          <template slot="option" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
          <template slot="noOptions">{{ $t('noOptions') }}</template>
        </multiselect>
      </div>
    </div>
    <template v-if="!selectedCurrency.crypto">
      <ul class="nav nav-tabs d-none">
        <li class="nav-item">
          <a class="nav-link active" data-toggle="tab" href="#transfer">{{ $t('deposit-section1') }}</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" data-toggle="tab" href="#creditcard">{{ $t('deposit-section2') }}</a>
        </li>
      </ul>
      <div class="tab-content tab-content-default">
        <div class="tab-pane fade active show" id="transfer" role="tabpanel">
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(createDeposit)">
              <div class="form-group">
                <label class="mr-sm-2">{{ $t('deposit-enterAmount') }}</label>
                <div class="row">
                  <validation-provider rules="required" name="Miktar" v-slot="{ classes, errors }" class="col-6">
                    <currency-input
                      id="amount"
                      class="form-control"
                      :class="classes"
                      :disabled="isDeposit"
                      v-model="amount"
                      :placeholder="$t('deposit-labelAmount')"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <validation-provider rules="required" name="Banka" v-slot="{ classes, errors }" class="col-6">
                    <multiselect
                      v-model="selectedBank"
                      :disabled="isDeposit"
                      deselect-label=""
                      select-label=""
                      selected-label=""
                      track-by="id"
                      label="name"
                      :placeholder="$t('deposit-dropdownBank')"
                      :options="bankList"
                      :searchable="false"
                      :allow-empty="false"
                      :class="classes"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">{{ `${option.bank}` }}</template>
                      <template slot="option" slot-scope="{ option }">{{ `${option.bank}` }}</template>
                      <template slot="noOptions">{{ $t('noOptions') }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                </div>
                <div v-if="selectedBank" class="d-flex justify-content-between mt-1">
                  <div
                    class="alert alert-warning"
                    role="alert"
                    v-html="
                      $t('deposit-limitWarning', {
                        amount: selectedBank ? selectedBank.min_pay_formatted : ''
                      })
                    "
                  ></div>
                </div>
                <div class="d-flex justify-content-between mt-1">
                  <p class="mb-0">{{ $t('deposit-availableLimit') }}</p>
                  <h6 class="mb-0">{{ userBalance }}</h6>
                </div>
                <div class="d-flex justify-content-between mt-1">
                  <button v-if="!isDeposit" type="submit" class="btn-gradient-primary btn-block">
                    {{ $t('deposit-btn-continue') }}
                  </button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
        <div class="tab-pane fade" id="creditcard" role="tabpanel">
          <div class="form-group">
            <label class="mr-sm-2">{{ $t('deposit-cc-name') }}</label>
            <div class="input-group">
              <input type="text" name="nameSurname" class="form-control" :placeholder="$t('deposit-cc-name')" />
            </div>
          </div>
          <div class="form-group">
            <label class="mr-sm-2">{{ $t('deposit-cc-number') }}</label>
            <div class="input-group">
              <input type="text" name="cardNo" class="form-control" :placeholder="$t('deposit-cc-number')" />
            </div>
          </div>
          <label class="mr-sm-2">{{ $t('deposit-cc-dateTime') }}</label>
          <div class="row">
            <div class="col-4">
              <div class="form-group">
                <label class="mr-sm-2">{{ $t('deposit-cc-month') }}</label>
                <input
                  type="text"
                  name="mounth"
                  class="form-control"
                  :placeholder="$t('deposit-cc-month-placeholder')"
                />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label class="mr-sm-2">{{ $t('deposit-cc-year') }}</label>
                <input type="text" name="year" class="form-control" :placeholder="$t('deposit-cc-year-placeholder')" />
              </div>
            </div>
            <div class="col-4">
              <div class="form-group">
                <label class="mr-sm-2">{{ $t('deposit-cc-cvv') }}</label>
                <input type="text" name="cvv" class="form-control" :placeholder="$t('deposit-cc-cvv')" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group" v-if="isDeposit">
        <div class="d-flex justify-content-center mt-1">
          <img id="bankLogo" :src="selectedBank.logo" />
        </div>
        <div class="d-flex justify-content-center mt-1 font-weight-bold">
          {{ transfer.provider.bank }}
        </div>
        <div class="d-flex justify-content-center align-items-center mt-1">
          <span class="mb-0 mr-1 font-weight-bold">{{ $t('deposit-bankRecipient') }}</span>
          <span class="mb-0">{{ transfer.provider.name }}</span>
          <input name="transferName" type="hidden" ref="transferName" v-model="transfer.provider.name" />
          <a class="ml-1" @click="copyInformation($refs.transferName)">
            <i class="ficon icon-layers"></i>
          </a>
        </div>
        <div class="d-flex justify-content-center align-items-center mt-1">
          {{ transfer.provider.address }}
          <input name="transferAddress" type="hidden" ref="transferAddress" v-model="transfer.provider.address" />
          <a class="ml-1" @click="copyInformation($refs.transferAddress)">
            <i class="ficon icon-layers"></i>
          </a>
        </div>
        <div class="d-flex justify-content-center align-items-center mt-1">
          <span class="mb-0 mr-1 font-weight-bold">{{ $t('deposit-transferDesc') }}</span>
          <span class="mb-0">{{ transfer.code }}</span>
          <input name="transferCode" type="hidden" ref="transferCode" v-model="transfer.code" />
          <a class="ml-1" @click="copyInformation($refs.transferCode)">
            <i class="ficon icon-layers"></i>
          </a>
        </div>
        <div class="row text-center mt-2">
          <div class="col-12 col-sm-6 text-center mt-2 order-2 order-sm-1">
            <button type="button" class="btn-gradient-secondary btn-block" @click="cancelDeposit">
              {{ $t('deposit-btn-cancel') }}
            </button>
          </div>
          <div class="col-12 col-sm-6 text-center mt-2 order-1 order-sm-2">
            <button type="button" class="btn-gradient-primary btn-block" @click="approveDeposit">
              {{ $t('deposit-btn-confirm') }}
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="d-flex justify-content-center mt-1">
        <span class="mb-0 mr-1 font-weight-bold">{{ $t('deposit-walletAddress') }}</span>
      </div>
      <div class="d-flex justify-content-center mt-1">
        <span class="walletAddress">{{ wallet.address }}</span>
        <input name="currencyWallet" type="hidden" ref="currencyWallet" v-model="wallet.address" />
      </div>
      <div class="d-flex justify-content-center mt-1">
        <button type="button" @click="copyInformation($refs.currencyWallet)" class="btn btn-blue ml-1 mb-0">
          <i class="ficon icon-layers"></i>
          {{ $t('wallet-detail-btn-copy') }}
        </button>
      </div>
      <template v-if="selectedCurrency.code === 'XRP' || selectedCurrency.code === 'XLM'">
        <div class="d-flex justify-content-center mt-3">
          <span class="font-weight-bold" v-if="selectedCurrency.code === 'XRP'">{{ $t('deposit-walletTag') }}</span>
          <span class="font-weight-bold" v-if="selectedCurrency.code === 'XLM'">{{ $t('deposit-memo') }}</span>
        </div>
        <div class="d-flex justify-content-center mt-1">
          <span>{{ targetValue }}</span>
          <input name="currencyWallet" type="hidden" ref="targetValue" v-model="targetValue" />
        </div>
        <div class="d-flex justify-content-center mt-1">
          <button type="button" @click="copyInformation($refs.targetValue)" class="btn btn-blue ml-1">
            <i class="ficon icon-layers"></i>
            {{ $t('wallet-detail-btn-copy') }}
          </button>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapState } from 'vuex';
import i18n from '../../config/_i18n';

import Multiselect from 'vue-multiselect';

import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import MessagesTR from 'vee-validate/dist/locale/tr.json';
import MessagesEN from 'vee-validate/dist/locale/en.json';
import MessagesNL from 'vee-validate/dist/locale/nl.json';
import MessagesME from 'vee-validate/dist/locale/en.json';
import MessagesET from 'vee-validate/dist/locale/et.json';
import MessagesDE from 'vee-validate/dist/locale/de.json';
import MessagesFR from 'vee-validate/dist/locale/fr.json';

let Messages;
if (i18n.locale === 'tr') {
  Messages = MessagesTR;
} else if (i18n.locale === 'nl') {
  Messages = MessagesNL;
} else if (i18n.locale === 'me') {
  Messages = MessagesME;
} else if (i18n.locale === 'et') {
  Messages = MessagesET;
} else if (i18n.locale === 'de') {
  Messages = MessagesDE;
} else if (i18n.locale === 'fr') {
  Messages = MessagesFR;
} else {
  Messages = MessagesEN;
}
Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule],
    message: Messages
  });
});

export default {
  name: 'DepositForm',
  components: {
    Multiselect,
    ValidationObserver,
    ValidationProvider
  },
  computed: {
    ...mapState({
      currency: (state) => state.global.currency,
      isLoading: (state) => state.global.isLoading,
      user: (state) => state.customer.user,
      isCrypto: (state) => state.deposit.isCrypto,
      wallet: (state) => state.deposit.wallet,
      bankList: (state) => state.deposit.bankList,
      userBalance: (state) => state.deposit.userBalance,
      isDeposit: (state) => state.deposit.isDeposit,
      isTransfer: (state) => state.deposit.isTransfer,
      transfer: (state) => state.deposit.transfer
    }),
    selectedBank: {
      get() {
        return this.$store.state.deposit.selectedBank;
      },
      set(value) {
        this.$store.commit('deposit/updateSelectedBank', value);
      }
    },
    selectedCurrency: {
      get() {
        return this.$store.state.deposit.selectedCurrency;
      },
      set(value) {
        this.$store.commit('deposit/updateSelectedCurrency', value);
      }
    },
    selectedBankCurrency: {
      get() {
        return this.$store.state.deposit.selectedBankCurrency;
      }
    },
    amount: {
      get() {
        return this.$store.state.deposit.amount;
      },
      set(value) {
        this.$store.commit('deposit/updateAmount', value);
      }
    },
    targetValue: function () {
      return this.user.id + 1000000000;
    },
    depositCurrency: function () {
      return this.currency.filter(function (currency) {
        return currency.deposit;
      });
    }
  },
  methods: {
    ...mapActions('global', ['GET_CURRENCY']),
    ...mapActions('deposit', [
      'GET_DEPOSIT_PROVIDERS',
      'GET_CURRENCY_BALANCE',
      'POST_CREATE_DEPOSIT',
      'PUT_APPROVE_DEPOSIT',
      'CANCEL_DEPOSIT',
      'GET_HISTORY'
    ]),
    getCoinSelection() {
      this.GET_HISTORY({ currency: this.selectedCurrency.code.toLowerCase(), isNextPage: false });
      this.GET_DEPOSIT_PROVIDERS(this.selectedCurrency.code.toLowerCase());
      this.GET_CURRENCY_BALANCE(this.selectedCurrency.code.toLowerCase());
      this.selectedBank = null;
      this.$refs.form.reset();
    },
    createDeposit() {
      if (this.amount >= this.selectedBank.min_pay) {
        this.POST_CREATE_DEPOSIT();
      } else {
        Vue.$toast.error(
          this.$t('deposit-invalidAmount', {
            amount: this.selectedBank.min_pay_formatted ? this.selectedBank.min_pay_formatted : ''
          })
        );
      }
    },
    approveDeposit() {
      this.$store
        .dispatch('deposit/PUT_APPROVE_DEPOSIT')
        .then(() => {
          this.$refs.form.reset();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelDeposit() {
      this.CANCEL_DEPOSIT();
    },
    copyInformation(params) {
      params.setAttribute('type', 'text');
      params.select();
      document.execCommand('copy');
      window.getSelection().removeAllRanges();
      params.setAttribute('type', 'hidden');
      Vue.$toast.info(this.$t('toast-copyText'));
    }
  },
  created() {
    this.GET_CURRENCY();
    this.GET_DEPOSIT_PROVIDERS(this.selectedCurrency.code.toLowerCase());
    this.GET_CURRENCY_BALANCE(this.selectedCurrency.code.toLowerCase());
  }
};
</script>

<style lang="scss" scoped>
.walletAddress {
  width: 100%;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  height: 40px;

  &:hover {
    overflow: auto;
    white-space: inherit;
    text-overflow: unset;
  }
}

@media screen and (max-width: 768px) {
  .nav.nav-tabs {
    height: 40px;
    margin-bottom: 10px;

    .nav-item {
      height: 40px;

      a {
        line-height: 20px;
      }
    }
  }
  #bankLogo {
    width: 100%;
  }
}
</style>

<template>
  <div class="col-12 text-center">
    <a class="changeLanguage" @click="changeLanguage('tr')"> <i class="flag-icon flag-icon-tr"></i> Türkçe </a>
    <a class="changeLanguage" @click="changeLanguage('en')"> <i class="flag-icon flag-icon-gb"></i> English </a>
    <a class="changeLanguage" @click="changeLanguage('nl')"> <i class="flag-icon flag-icon-nl"></i> Dutch </a>
    <a class="changeLanguage" @click="changeLanguage('me')"> <i class="flag-icon flag-icon-me"></i> Montenegrin </a>
    <a class="changeLanguage" @click="changeLanguage('et')"> <i class="flag-icon flag-icon-ee"></i> Eestlane </a>
    <a class="changeLanguage" @click="changeLanguage('de')"> <i class="flag-icon flag-icon-de"></i> German </a>
    <a class="changeLanguage" @click="changeLanguage('fr')"> <i class="flag-icon flag-icon-fr"></i> Français </a>
  </div>
</template>

<script>
import { changeLanguages } from '../../config/_i18n';

export default {
  name: 'Language',
  methods: {
    changeLanguage(lang) {
      changeLanguages(lang, true);
    }
  }
};
</script>

<style scoped lang="scss">
.changeLanguage {
  color: #6b6f82 !important;
  margin: 5px 10px;
  display: inline-block;
}
</style>

<template>
  <div class='content-wrapper'>
    <Breadcrumb :title="$t('miracle_deal.striker_list')"/>
    <div class='row'>
      <div class="col-12 col-lg-6">
        <div class="card card-custom">
          <div class="card-body">
            <div class="row justify-content-center px-8 px-lg-10">
              <div class="col-12  justify-content-center">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <form method="post" @submit.prevent="handleSubmit(createStriker)">
                    <div data-wizard-type="step-content" data-wizard-state="current">
                      <div class="row">
                        <div class="col-xl-12">
                          <div class="form-group">
                            <label class="mr-sm-2">{{ $t('miracle_deal.duration') }}</label>
                            <div class="row">
                              <div class="col-lg-6 col-sm-12">
                                <validation-provider rules="required|min_value:0" :name="$t('miracle_deal.duration')"
                                                     v-slot="{ classes, errors }">
                                  <input class="form-control" :class="classes"
                                         :min="selectedDurationType.min"
                                         :max="selectedDurationType.max"
                                         type="number"
                                         v-model="strikerOrder.duration"
                                         :placeholder="selectedDurationType.placeHolder"
                                  />
                                  <span class="form-text text-muted">{{ $t('miracle_deal.duration_desc') }}</span>
                                  <span class="error__message">{{ errors[0] }}</span>
                                </validation-provider>
                              </div>
                              <div class="col-lg-6 col-sm-12">
                                <validation-provider rules="required" name="durationType" v-slot="{ classes, errors }">
                                  <multiselect
                                      v-model="selectedDurationType"
                                      :options="signDurationTypes"
                                      :internal-search="true"
                                      :allow-empty="false"
                                      open-direction="bottom"
                                      deselect-label=''
                                      select-label=''
                                      selected-label=''
                                      track-by='name'
                                      label='name'
                                      placeholder='Select Type'
                                      :class="classes"
                                  >
                                    <template slot='singleLabel' slot-scope='{ option }'>{{ option.name }}</template>
                                    <template slot='option' slot-scope='{ option }'>{{ option.name }}</template>
                                    <template slot='noOptions'>{{ 'List is empty' }}</template>
                                  </multiselect>
                                  <span class="error__message">{{ errors[0] }}</span>
                                </validation-provider>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="mr-sm-2">{{ $t('miracle_deal.sign_duration') }}</label>
                            <div class="row">
                              <div class="col-lg-6 col-sm-12">
                                <validation-provider rules="required|min_value:0"
                                                     :name="$t('miracle_deal.sign_duration')"
                                                     v-slot="{ classes, errors }">
                                  <input
                                      class="form-control"
                                      :max="selectedSignDurationType.max"
                                      :min="selectedSignDurationType.min"
                                      :class="classes"
                                      type="number"
                                      v-model="strikerOrder.sign_duration"
                                      :placeholder="selectedSignDurationType.placeHolder"
                                  />
                                  <span class="form-text text-muted">{{ $t('miracle_deal.sign_duration_desc') }}</span>
                                  <span class="error__message">{{ errors[0] }}</span>
                                </validation-provider>
                              </div>
                              <div class="col-lg-6 col-sm-12">
                                <multiselect
                                    v-model="selectedSignDurationType"
                                    :options="signDurationTypes"
                                    :internal-search="true"
                                    :allow-empty="false"
                                    open-direction="bottom"
                                    deselect-label=''
                                    select-label=''
                                    selected-label=''
                                    track-by='name'
                                    label='name'
                                    placeholder='Select Type'
                                >
                                  <template slot='singleLabel' slot-scope='{ option }'>{{ option.name }}</template>
                                  <template slot='option' slot-scope='{ option }'>{{ option.name }}</template>
                                  <template slot='noOptions'>{{ 'List is empty' }}</template>
                                </multiselect>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="mr-sm-2">{{ $t('miracle_deal.currency') }}</label>
                            <div class="row">
                              <div class="col-12">
                                <validation-provider rules="required" :name="$t('commons.currency')"
                                                     v-slot="{ classes, errors }">
                                  <multiselect
                                      v-model="selectedCurrency"
                                      :options="crypto"
                                      :internal-search="true"
                                      open-direction="bottom"
                                      :loading="currenciesIsLoading"
                                      @input="onChangeCurrency"
                                      deselect-label=''
                                      select-label=''
                                      selected-label=''
                                      track-by='name'
                                      label='name'
                                      :placeholder="$t('miracle_deal.choosen_currency')"
                                      :class="classes"
                                  >
                                    <template slot='singleLabel' slot-scope='{ option }'>{{ option.name }}</template>
                                    <template slot='option' slot-scope='{ option }'>{{ option.name }}</template>
                                    <template slot='noOptions'>{{ 'List is empty' }}</template>
                                  </multiselect>
                                  <span class="form-text text-muted">{{ $t('miracle_deal.currency_desc') }}</span>
                                  <span class="error__message">{{ errors[0] }}</span>
                                </validation-provider>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="mr-sm-2">{{ $t('miracle_deal.quantity') }}</label>
                            <div class="row">
                              <div class="col-lg-12">
                                <validation-provider rules="required" :name="$t('miracle_deal.quantity')"
                                                     v-slot="{ classes, errors }">
                                  <currency-input
                                      class="form-control"
                                      :class="classes"
                                      :precision="currencyPrecision"
                                      v-model="strikerOrder.quantity"
                                      :placeholder="$t('miracle_deal.quantity')"
                                  />

                                  <span class="form-text text-muted">{{ $t('miracle_deal.quantity_desc') }}</span>
                                  <span class="error__message">{{ errors[0] }}</span>
                                </validation-provider>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label class="mr-sm-2">{{ $t('miracle_deal.ratio') }}</label>
                            <div class="row">
                              <div class="col-12">
                                <validation-provider rules="required|min_value:0" :name="$t('miracle_deal.ratio')"
                                                     v-slot="{ classes, errors }">
                                  <input
                                      type="number"
                                      min="0.01"
                                      max="100"
                                      step="0.01"
                                      pattern="^\d*(\.\d{0,2})?$"
                                      class="form-control"
                                      :class="classes"
                                      v-model="strikerOrder.ratio"
                                      :placeholder="$t('miracle_deal.ratio_desc')"
                                  />
                                  <span class="form-text text-muted">{{ $t('miracle_deal.ratio_desc') }}</span>
                                  <span class="error__message">{{ errors[0] }}</span>
                                </validation-provider>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-end  mt-2 pt-10">
                      <button type="submit" class="btn-gradient-success btn-block">Gönder</button>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="row justify-content-center px-8 px-lg-10">
          <div class="col-12  justify-content-center">
            <div class="bd-content p-4"><!---->
              <h3 class="h3">Açıklama</h3>
              <p>...</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex';
import Breadcrumb from '@/components/layout/Breadcrumb';
import i18n from '@/config/_i18n'
import Multiselect from "vue-multiselect";
import {ValidationObserver, ValidationProvider} from "vee-validate";

const signDurations = [
  {value: "2", name: i18n.t("commons.day"), min: 1, max: 31, placeHolder: "1 - 31"},
  {value: "3", name: i18n.t("commons.month"), min: 1, max: 12, placeHolder: "1 - 12"},
  {value: "4", name: i18n.t("commons.year"), min: 1, max: 100, placeHolder: "1 - 100"},
];


export default {
  name: 'StrikerOrder',
  data() {
    return {
      strikerOrder: {
        duration: '',
        durationType: '',
        sign_duration: '',
        sign_duration_type: '',
        quantity: null,
        ratio: ''
      },
      selectedCurrency: null,
      selectedUser: '',
      currencyPrecision: 2,
      signDurationTypes: signDurations,
      selectedDurationType: signDurations[0],
      selectedSignDurationType: signDurations[0],
      isSubmitting: false
    }
  },
  components: {
    Breadcrumb,
    Multiselect,
    ValidationObserver,
    ValidationProvider
  },
  created() {
    this.getData();
    this.GET_CURRENCY();
  },
  computed: {
    ...mapState({
      currenciesIsLoading: state => state.global.currenciesIsLoading,
      crypto: (state) => state.global.crypto,
    }),

  },
  methods: {
    ...mapActions('striker', ['POST_STRIKER']),
    ...mapActions('global', ['GET_CURRENCY']),
    createStriker() {
      let data = {
        ...this.strikerOrder,
        currency_id: this.selectedCurrency?.id || '',
        duration_type: this.selectedDurationType?.value || '',
        sign_duration_type: this.selectedSignDurationType?.value || ''
      };
      // console.log(data)
      this.isSubmitting = true;
      this.POST_STRIKER(data)
          .then(() => {
          })
          .catch(() => {
          })
          .finally(() => {
            this.isSubmitting = false
          })
    },
    getData(query) {
      console.log(query)
      console.log("esc:", escape(query))
    },
    onChangeCurrency() {
      this.currencyPrecision = this.selectedCurrency?.decimal || 8;
    }
  },
  getters: {
    getCrypto() {
      return this.currencies.filter(function (currency) {
        return currency.crypto;
      });
    }
  },
};
</script>

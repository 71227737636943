<template>
  <div class='content-wrapper'>
    <Breadcrumb :title="$t('miracle_deal.keeper_list')"/>
    <div class='row'>
      <div class="col-12">
        <div class="card card-custom">
          <div class='card-body'>
            <div v-if="isLoading">
              <div class="text-center">{{ $t('commons.data_loading') }}...</div>
            </div>
            <table class="table table-striped table-bordered collapsed" style="width:100%" ref="kt_datatable" v-else>
              <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">{{ $t('commons.currency_code') }}</th>
                <th scope="col">{{ $t('commons.quantity') }}</th>
                <th scope="col">{{ $t('miracle_deal.fee') }}</th>
                <th scope="col">{{ $t('commons.duration') }}</th>
                <th scope="col">{{ $t('miracle_deal.customer_ratio') }}</th>
                <th scope="col">{{ $t('miracle_deal.countdown') }}</th>
                <th scope="col">{{ $t('commons.status') }}</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in keeper" :key="'keeper-' + index">
                <th class="font-weight-bolder">{{ item.id }}</th>
                <td>
                    <span style="width: 250px;">
                      <div class="d-flex align-items-center">
                        <div class="symbol symbol-40 flex-shrink-0">
                          <div class="media align-items-center">
                            <img :src="`${iconUrl}${item.currency_code.toLowerCase()}.png`" height="30" alt=""
                                 class="cc mr-2 font-large-2 warning d-none d-md-block">
                          </div>
                        </div>
                        <div class="ml-3">
                          <div class="text-dark-75 font-weight-bold line-height-sm">{{ item.currency_code }}</div>
                        </div>
                      </div>
                    </span>
                </td>
                <td>{{ item.quantity }}</td>
                <td class="text-muted">% {{ item.fee }}</td>
                <td class="text-muted">{{ item.duration }} {{ textDuration(item.duration_type) }}</td>
                <td class="text-muted">% {{ item.ratio }}</td>
                <td>
                    <span style="width: 250px;">
                      <div class="d-flex align-items-center">
                        <div class="ml-2">
                          <div v-if="item.process_date" class="text-primary font-weight-bold line-height-sm">{{
                              item.process_date  | dateFormat
                            }}</div>
                        </div>
                      </div>
                    </span>
                </td>
                <td>
                    <span
                        :class="['label label-lg label-inline', item.status === 0 ? 'label-light-danger' : item.status === 1 ? 'label-light-warning' : 'label-light-primary'] ">
                      {{
                        item.status === 0 ? $t('miracle_deal.close') : item.status === 1 ? $t('miracle_deal.waiting') : $t('miracle_deal.in_progress', {"space": "&nbsp;"})
                      }}
                    </span>
                </td>
                <td class="text-right no-sort">
                  <button v-if="item.status === 1" type="button" @click="deleteKeeper(item.id)"
                          class="btn btn-icon  btn-gradient-secondary" :title="$t('commons.delete')">
                    <i class="la la-trash text-white"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';
import dateFormat from '@/config/_date-format';
import Breadcrumb from '@/components/layout/Breadcrumb';
import Swal from "sweetalert2";

export default {
  name: 'KeeperList',
  data() {
    return {
      isLoading: true,
      iconUrl: "https://cdn.test.ofinans.com/images/currency/"
    }
  },
  components: {
    Breadcrumb,
  },
  created() {
    const self = this;
    self.$store.dispatch('keeper/GET_MY_KEEPER').then(function () {
      self.isLoading = false;
    })

  },
  computed: {
    ...mapState({
      keeper: state => state.keeper.keeperMyList,
    })
  },
  filters: {
    dateFormat
  },
  methods: {
    ...mapActions('keeper', ['DELETE_KEEPER']),
    textDuration(id) {
      switch (id) {
        case 0:
          return 'min';
        case 1:
          return 'h';
        case 2:
          return 'd';
        case 3:
          return 'w';
        case 4:
          return 'm';
        default:
          return 'y';
      }
    },
    deleteKeeper(id) {
      const vm= this;
      Swal.fire({
        title: this.$t('commons.are_you_sure'),
        text: this.$t('commons.wont_be_able_to_revert'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t('commons.yes_delete_it'),
        cancelButtonText: this.$t('commons.cancel'),
      }).then(function (result) {
        if (result.value) {
          vm.DELETE_KEEPER(id);
        }
      });
    },
  }
};
</script>

<template>
  <div class="card-body">
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form method="post" @submit.prevent="handleSubmit(upgradeTwoNdLevel)">
        <div class="col-12">
          <div class="row">
            <label for="country" class="col-12 col-form-label col-form-label-lg font-small-3">{{
              $t('accountSettings-country')
            }}</label>
            <div class="w-100">
              <multiselect
                v-model="selectedCountry"
                :placeholder="$t('accountSettings-chooseCountry')"
                track-by="label"
                label="label"
                :options="countryCodes"
                :option-height="104"
                :show-labels="false"
                @input="getFormToCountry"
                class="multiselectRegular"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="props">
                  <div class="option">
                    <span class="option__title">{{ props.option.label }}</span>
                  </div>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option">
                    <span class="option__title">{{ props.option.label }}</span>
                  </div>
                </template>
                <template slot="noOptions">{{ $t('noOptions') }}</template>
              </multiselect>
            </div>
          </div>
        </div>

        <div v-if="selectedCountry" class="row">
          <div :class="formParentClass(item)" class="col-12" v-for="(item, index) in formTwoData" :key="index">
            <validation-provider
              class=""
              :name="$t(`${item.data.label}`)"
              :rules="{ required: isRequired(item.name) }"
              v-slot="{ classes, errors }"
              v-if="item.name == 'City'"
            >
              <label :for="item.name" class="col-sm-12 col-form-label col-form-label-lg font-small-3">
                {{ $t(`${item.data.label}`) }}
              </label>
              <div class="w-100">
                <multiselect
                  v-model="selectedCity"
                  :placeholder="$t('account-dropdownChoosen')"
                  label="title"
                  track-by="title"
                  :options="cities"
                  :option-height="104"
                  :show-labels="false"
                  @select="getTowns"
                  class="multiselectRegular"
                  :class="classes"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <div class="option">
                      <span class="option__title">{{ props.option.title }}</span>
                    </div>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option">
                      <span class="option__title">{{ props.option.title }}</span>
                    </div>
                  </template>
                  <template slot="noOptions">{{ $t('noOptions') }}</template>
                </multiselect>
                <span class="error__message">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <validation-provider
              class=""
              :name="$t(`${item.data.label}`)"
              :rules="{ required: isRequired(item.name) }"
              v-slot="{ classes, errors }"
              v-else-if="item.name == 'StateProvinceCode'"
            >
              <label :for="item.name" class="col-12 col-form-label col-form-label-lg font-small-3">
                {{ $t(`${item.data.label}`) }}
              </label>
              <div class="w-100">
                <multiselect
                  v-model="selectedTown"
                  :placeholder="$t('account-dropdownChoosen')"
                  :label="$t(`${item.data.label}`)"
                  track-by="title"
                  :options="towns"
                  :option-height="104"
                  :show-labels="false"
                  class="multiselectRegular"
                  :class="classes"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <div class="option">
                      <span class="option__title">{{ props.option.title }}</span>
                    </div>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option">
                      <span class="option__title">{{ props.option.title }}</span>
                    </div>
                  </template>
                  <template slot="noOptions">{{ $t('noOptions') }}</template>
                </multiselect>
                <span class="error__message">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <validation-provider
              class=""
              :name="$t(`${item.data.label}`)"
              :rules="{ required: isRequired(item.name) }"
              v-slot="{ classes, errors }"
              v-else-if="item.name == 'Gender'"
            >
              <label :for="item.name" class="col-12 col-form-label col-form-label-lg font-small-3">
                {{ $t(`${item.data.label}`) }}
              </label>
              <div class="w-100">
                <multiselect
                  v-model="twoNdLevel[item.name]"
                  :placeholder="$t('account-dropdownChoosen')"
                  :label="$t(`${item.data.label}`)"
                  track-by="label"
                  :options="gender"
                  :option-height="104"
                  :show-labels="false"
                  class="multiselectRegular"
                  :class="classes"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <div class="option">
                      <span class="option__title">{{ $t(`${props.option.label}`) }}</span>
                    </div>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option">
                      <span class="option__title">{{ $t(`${props.option.label}`) }}</span>
                    </div>
                  </template>
                  <template slot="noOptions">{{ $t('noOptions') }}</template>
                </multiselect>
                <span class="error__message">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <validation-provider
              class=""
              :name="$t(`${item.name}`)"
              :rules="{ required: isRequired(item.name) }"
              v-slot="{ classes, errors }"
              v-else-if="item.data.enum"
            >
              <label :for="item.name" class="col-12 col-form-label col-form-label-lg font-small-3">
                {{ $t(`${item.name}`) }}
              </label>
              <div class="w-100">
                <multiselect
                  :class="classes"
                  :placeholder="$t('account-dropdownChoosen')"
                  v-model="twoNdLevel[item.name]"
                  :options="item.data.enum"
                  :allow-empty="false"
                  :show-labels="false"
                  :label="$t(`${item.label}`)"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <div class="option">
                      <span class="option__title">{{ $t(`${props.option}`) }}</span>
                    </div>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="option">
                      <span class="option__title">{{ $t(`${props.option}`) }}</span>
                    </div>
                  </template>
                  <template slot="noOptions">{{ $t('noOptions') }}</template>
                </multiselect>
                <span class="error__message">{{ errors[0] }}</span>
              </div>
            </validation-provider>
            <validation-provider
              class=""
              :name="$t(`${item.data.label}`)"
              :rules="{ required: isRequired(item.name) }"
              v-slot="{ classes, errors }"
              v-else
            >
              <label :for="item.name" class="col col-form-label col-form-label-lg font-small-3">
                {{ $t(`${item.data.label}`) }}
              </label>
              <div class="col form-control-item">
                <input
                  class="form-control"
                  :class="classes"
                  :id="item.name"
                  :type="item.data.type == 'integer' ? 'number' : 'text'"
                  :min="findMinValue(item.name)"
                  :max="findMaxValue(item.name)"
                  :placeholder="$t(`${item.data.label}`)"
                  v-model="twoNdLevel[item.name]"
                />
                <span class="error__message">{{ errors[0] }}</span>
              </div>
            </validation-provider>
          </div>
        </div>
        <div class="row text-center mt-2">
          <div class="col-12 text-center mt-2 order-2 order-sm-1" :class="selectedCountry == null ? '' : 'col-sm-6'">
            <button type="button" class="btn-gradient-secondary btn-block" @click="cancelUpgrade">
              {{ $t('accountSettings-btnCancel') }}
            </button>
          </div>
          <div class="col-12 col-sm-6 text-center mt-2 order-1 order-sm-2">
            <button
              :type="isLoading ? 'button' : 'submit'"
              class="btn-gradient-primary btn-block"
              v-if="selectedCountry"
            >
              <template v-if="isLoading">
                {{ $t('accountSettings-btnSending') }}
              </template>
              <template v-else>
                {{ $t('accountSettings-btnSave') }}
              </template>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import i18n from '../../config/_i18n';

import Multiselect from 'vue-multiselect';

import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import MessagesTR from 'vee-validate/dist/locale/tr.json';
import MessagesEN from 'vee-validate/dist/locale/en.json';
import MessagesNL from 'vee-validate/dist/locale/nl.json';
import MessagesME from 'vee-validate/dist/locale/en.json';
import MessagesET from 'vee-validate/dist/locale/et.json';
import MessagesDE from 'vee-validate/dist/locale/de.json';
import MessagesFR from 'vee-validate/dist/locale/fr.json';

let Messages;
if (i18n.locale === 'tr') {
  Messages = MessagesTR;
} else if (i18n.locale === 'nl') {
  Messages = MessagesNL;
} else if (i18n.locale === 'me') {
  Messages = MessagesME;
} else if (i18n.locale === 'et') {
  Messages = MessagesET;
} else if (i18n.locale === 'de') {
  Messages = MessagesDE;
} else if (i18n.locale === 'fr') {
  Messages = MessagesFR;
} else {
  Messages = MessagesEN;
}
Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule],
    message: Messages.messages[rule]
  });
});

export default {
  name: 'SecondLevelUpgrade',
  components: {
    Multiselect,
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      selectedCountry: null,
      selectedCity: null,
      selectedTown: null,
      gender: [
        { id: 'M', label: 'Male' },
        { id: 'F', label: 'Female' }
      ]
    };
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.account.level_2.isLoading,
      countryCodes: (state) => state.account.countryCodes,
      twoNdLevel: (state) => state.account.twoNdLevel,
      formTwoData: (state) => state.account.formTwoData,
      cities: (state) => state.customer.cities,
      towns: (state) => state.customer.towns
    })
  },
  methods: {
    ...mapActions('account', ['GET_COUNTRY_CODES', 'GET_FORM_2ND_LEVEL', 'POST_2ND_LEVEL']),
    ...mapActions('customer', ['GET_CITIES', 'GET_TOWNS']),
    renderForm(payload) {
      let content = {};
      Object.entries(payload.properties).forEach(([key, value]) => {
        if (key == 'City') content[key] = this.selectedCity.title;
        else if (key == 'StateProvinceCode') content[key] = this.selectedTown.title;
        else if (key == 'Gender') content[key] = this.twoNdLevel[key].id;
        else content[key] = value.type != 'object' ? this.twoNdLevel[key] : this.renderForm(value);
      });
      return content;
    },
    upgradeTwoNdLevel() {
      let contentDataFields = {};
      let obj = this.$store.state.account.responseNdLevel.DataFields.properties;
      Object.entries(obj).forEach(([key, value]) => {
        if (key == 'NationalIds') contentDataFields[key] = [this.renderForm(value)];
        else contentDataFields[key] = this.renderForm(value);
      });
      this.POST_2ND_LEVEL({ selectedCountry: this.selectedCountry.countryCode, content: contentDataFields });
    },
    isRequired(field) {
      let allRequired = this.$store.state.account.formTwoRequired;
      return allRequired.includes(field);
    },
    getFormToCountry() {
      if (this.selectedCountry) {
        this.GET_FORM_2ND_LEVEL(this.selectedCountry.countryCode);
        this.GET_CITIES(this.selectedCountry.countryCode);
      }
      this.selectedCity = null;
      this.selectedTown = null;
    },
    getTowns() {
      this.$nextTick(function () {
        this.GET_TOWNS(this.selectedCity.id);
      });
      this.selectedTown = '';
    },
    cancelUpgrade() {
      this.$store.commit('account/cancelUpgrade', false);
    },
    findMaxValue(field) {
      switch (field) {
        case 'DayOfBirth':
          return 31;
        case 'MonthOfBirth':
          return 12;
        case 'YearOfBirth':
          return new Date().getFullYear();
        default:
          return '';
      }
    },
    findMinValue(field) {
      switch (field) {
        case 'YearOfBirth':
          return 1950;
        default:
          return 1;
      }
    },
    formParentClass(item) {
      const nameFields = ['FirstGivenName', 'MiddleName', 'FirstSurName'];
      const birthDayFields = ['DayOfBirth', 'MonthOfBirth', 'YearOfBirth'];
      const buildingNumberFields = ['BuildingNumber', 'BuildingName', 'StreetName', 'UnitNumber'];
      const cityFields = ['BuildingNumber', 'BuildingName', 'StreetName', 'UnitNumber'];
      const tripleFields = nameFields.concat(birthDayFields, buildingNumberFields, cityFields);

      if (tripleFields.includes(item.name)) return 'col-md-4 triple';
    }
  },
  created() {
    this.GET_COUNTRY_CODES();
  },
  destroyed() {
    this.cancelUpgrade();
  }
};
</script>

<style lang="scss" scoped>
.col-form-label {
  padding-bottom: 5px;
  color: #5f5f5f;
}

.form-control-item,
.col-form-label {
  max-width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.triple {
  padding-bottom: 20px;
  padding-top: 10px;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 1px;
    border-bottom: 1px solid #f3f3f3;
  }
}
</style>

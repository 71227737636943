import i18n from '@/config/_i18n'

/**
 * 
 * @param {*} res | server response
 * @returns 
 */
export const getSuccessServiceMsg = (res) => {
    let msgKey;
    if(res?.data?.message_key) msgKey = `service.${res?.data?.message_key}`;
    else if(res?.data?.message?.message_key) msgKey = `service.${res?.data?.message?.message_key}`;
    else if(res?.data?.message) msgKey = res?.data?.message;
    else msgKey = 'service.operation_success';
    return i18n.t(msgKey);
}

/**
 * 
 * @param {*} res | server response
 * @returns 
 */
export const getErrorServiceMsg = (res) => {
    let msgKey;
    if(res?.data?.message_key) msgKey = `service.${res?.data?.message_key}`;
    else if(res?.data?.message?.message_key) msgKey = `service.${res?.data?.message?.message_key}`;
    else if(res?.data?.message) msgKey = res?.data?.message;
    else msgKey = 'service.fatal_error';
    return i18n.t(msgKey);
}

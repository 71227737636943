<template>
  <nav
    class="
      header-navbar
      navbar-expand-md navbar navbar-with-menu navbar-without-dd-arrow
      fixed-top
      navbar-light navbar-bg-color
    "
  >
    <div class="navbar-wrapper">
      <div class="navbar-header d-md-none">
        <ul class="nav navbar-nav flex-row">
          <li class="nav-item mobile-menu d-md-none mr-auto">
            <a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#"><i class="ft-menu font-large-1"></i></a>
          </li>
          <li class="nav-item d-md-none">
            <a class="navbar-brand" href="/"
              ><img
                class="brand-logo d-none d-md-block"
                alt="Miracle Cash&More"
                src="../../assets/images/logo/logo.202104212035.png" /><img
                class="brand-logo d-sm-block d-md-none"
                alt="Miracle Cash&More"
                src="../../assets/images/logo/logo-sm.202104212035.jpg"
            /></a>
          </li>
          <li class="nav-item d-md-none">
            <a class="nav-link open-navbar-container" data-toggle="collapse" data-target="#navbar-mobile"
              ><i class="la la-ellipsis-v"> </i
            ></a>
          </li>
        </ul>
      </div>
      <div class="navbar-container">
        <div class="collapse navbar-collapse" id="navbar-mobile">
          <ul class="nav navbar-nav mr-auto float-left">
            <li class="nav-item d-none d-md-block">
              <a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#"><i class="ft-menu"> </i></a>
            </li>
          </ul>
          <ul class="nav navbar-nav float-right">
            <li class="dropdown dropdown-language nav-item">
              <a
                class="dropdown-toggle nav-link"
                id="dropdown-flag"
                href="#"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i
                  class="flag-icon"
                  v-bind:class="{
                    'flag-icon-gb': lang === 'en',
                    'flag-icon-tr': lang === 'tr',
                    'flag-icon-nl': lang === 'nl',
                    'flag-icon-me': lang === 'me',
                    'flag-icon-ee': lang === 'et',
                    'flag-icon-de': lang === 'de',
                    'flag-icon-fr': lang === 'fr'
                  }"
                ></i>
                <span class="selected-language"></span>
              </a>
              <div class="dropdown-menu" aria-labelledby="dropdown-flag">
                <a class="dropdown-item" href="#" @click="changeLanguage('tr')">
                  <i class="flag-icon flag-icon-tr"></i> Türkçe
                </a>
                <a class="dropdown-item" href="#" @click="changeLanguage('en')">
                  <i class="flag-icon flag-icon-gb"></i> English
                </a>
                <a class="dropdown-item" href="#" @click="changeLanguage('nl')">
                  <i class="flag-icon flag-icon-nl"></i> Dutch
                </a>
                <a class="dropdown-item" href="#" @click="changeLanguage('me')">
                  <i class="flag-icon flag-icon-me"></i> Montenegrin
                </a>
                <a class="dropdown-item" href="#" @click="changeLanguage('et')">
                  <i class="flag-icon flag-icon-ee"></i> Eestlane
                </a>
                <a class="dropdown-item" href="#" @click="changeLanguage('de')">
                  <i class="flag-icon flag-icon-de"></i> German
                </a>
                <a class="dropdown-item" href="#" @click="changeLanguage('fr')">
                  <i class="flag-icon flag-icon-fr"></i> Français
                </a>
              </div>
            </li>
            <li class="dropdown dropdown-notification nav-item" v-bind:class="{ show: showNotification }">
              <a class="nav-link nav-link-label" href="#" @click="readNotifications"
                ><i class="ficon ft-bell"></i>
                <span
                  class="badge badge-pill badge-default badge-danger badge-default badge-up badge-glow"
                  v-if="unreadNotifications"
                >
                  {{ unreadNotifications }}
                </span>
              </a>
              <ul
                class="dropdown-menu dropdown-menu-media dropdown-menu-right"
                v-bind:class="{ show: showNotification }"
              >
                <li class="dropdown-menu-header">
                  <h6 class="dropdown-header m-0">
                    <span class="grey darken-2">{{ $t('top-menu-notification') }}</span>
                  </h6>
                  <span class="notification-tag badge badge-default badge-danger float-right m-0">
                    {{ unreadNotifications }} {{ $t('header-notification-title') }}
                  </span>
                </li>
                <li class="scrollable-container media-list w-100">
                  <div class="media" v-for="(item, index) in userNotifications" :key="'notifications-' + index">
                    <div class="media-left align-self-center">
                      <i class="ft-check-circle icon-bg-circle bg-cyan"></i>
                    </div>
                    <div class="media-body">
                      <h6 class="media-heading">{{ item.all_data.data.title }}</h6>
                      <p class="notification-text font-small-3 text-muted">
                        {{ item.all_data.data.message }}
                      </p>
                      <small>
                        <time class="media-meta text-muted">{{ item.time_ago }}</time>
                      </small>
                    </div>
                  </div>
                </li>
                <li class="dropdown-menu-footer">
                  <a class="dropdown-item text-muted text-center" @click="getMore">
                    {{ $t('top-menu-loadMore-notification') }}
                  </a>
                </li>
              </ul>
            </li>
            <li class="dropdown dropdown-notification nav-item">
              <router-link class="nav-link nav-link-label" to="/wallet">
                <i class="ficon icon-wallet"></i>
              </router-link>
            </li>
            <li class="dropdown dropdown-notification nav-item" v-cloak>
              <router-link class="nav-link nav-link-label user-level-link" to="/personal/account-settings">
                <i class="ficon ft-award"></i> {{ user.level_str }}
              </router-link>
            </li>
            <li class="dropdown dropdown-user nav-item">
              <a class="dropdown-toggle nav-link dropdown-user-link" href="#" data-toggle="dropdown">
                <span class="avatar avatar-online">
                  <img src="../../assets/images/portrait/small/avatar-s-1.png" alt="avatar" />
                </span>
                <span class="user-name text-bold-700 mr-1">{{ availableBalance }}</span>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <router-link class="dropdown-item" to="/personal/account-settings">
                  <i class="ft-award"></i>{{ user.fullname }}
                </router-link>
                <div class="dropdown-divider"></div>
                <router-link class="dropdown-item" to="/wallet">
                  <i class="icon-wallet"></i>{{ $t('top-menu-wallet') }}
                </router-link>
                <router-link class="dropdown-item" to="/personal/history">
                  <i class="ft-check-square"></i>{{ $t('header-profile-history') }}
                </router-link>
                <div class="dropdown-divider"></div>
                <button type="button" class="dropdown-item" @click="logoutBtn">
                  <i class="ft-power"></i> {{ $t('header-profile-logout') }}
                </button>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'LeftMenu',
  data() {
    return {
      showNotification: false
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.global.lang,
      userNotifications: (state) => state.customer.userNotifications,
      user: (state) => state.customer.user,
      availableBalance: (state) => state.wallet.approximate_balance,
      unreadNotifications: (state) => state.customer.unreadNotifications,
      next: (state) => state.customer.next
    })
  },
  methods: {
    ...mapActions('customer', ['GET_USER', 'GET_LOGOUT', 'GET_USER_NOTIFICATIONS', 'POST_READ_NOTIFICATIONS']),
    ...mapActions('wallet', ['GET_BALANCE']),
    ...mapActions('global', ['CHANGE_LANGUAGE']),
    logoutBtn() {
      this.GET_LOGOUT();
    },
    getMore() {
      if (this.next) this.GET_USER_NOTIFICATIONS({ isNextPage: true });
    },
    readNotifications() {
      this.showNotification = !this.showNotification;
      if (this.$store.state.customer.unreadNotifications > 0) this.POST_READ_NOTIFICATIONS();
    },
    changeLanguage(lang) {
      this.CHANGE_LANGUAGE({ lang: lang, refresh: true });
    }
  },
  created() {
    this.GET_USER();
    this.GET_USER_NOTIFICATIONS({ isNextPage: false });
    setInterval(() => {
      this.GET_USER_NOTIFICATIONS({ isNextPage: false });
    }, 10000);
    this.GET_BALANCE();
  }
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 600px) {
  .user-level-link {
    font-size: 11px !important;
  }
}
</style>

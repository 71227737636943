<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form method="post" @submit.prevent="handleSubmit(getProvidersBtn)">
      <div v-if="!showAddBankAccount">
        <div v-if="!isTransfer">
          <div class="form-group">
            <label class="mr-sm-2">{{ $t('withdrawal-type') }}</label>
            <validation-provider rules="required" v-slot="{ classes, errors }">
              <div class="input-group">
                <multiselect
                  v-model="selectedCurrency"
                  @input="getCoinSelection"
                  deselect-label=""
                  select-label=""
                  :class="classes"
                  selected-label=""
                  track-by="name"
                  :label="$t('withdrawal-bank')"
                  :placeholder="$t('withdrawal-dropdownCryptoWallet')"
                  :options="withdrawCurrency"
                  :searchable="false"
                  :allow-empty="false"
                >
                  <template slot="singleLabel" slot-scope="{ option }"
                    >{{ `${option.name} (${option.code})` }}
                  </template>
                  <template slot="option" slot-scope="{ option }">{{ `${option.name} (${option.code})` }}</template>
                  <template slot="noOptions">{{ $t('noOptions') }}</template>
                </multiselect>
                <span class="error__message">{{ errors[0] }}</span>
              </div>
            </validation-provider>
          </div>
          <div v-if="selectedCurrency">
            <div class="form-group">
              <div class="d-flex justify-content-between">
                <label class="mr-sm-2" v-if="selectedCurrency.crypto">{{ $t('withdrawal-labelCrypto') }}</label>
                <label class="mr-sm-2" v-else>{{ $t('withdrawal-labelBank') }}</label>
                <div>
                  <span class="add-wallet" @click="addWalletBtn">
                    <template v-if="selectedCurrency.crypto">{{ $t('withdrawal-addCryptoWalletModal') }}</template>
                    <template v-else>{{ $t('withdrawal-addAccountModal') }}</template>
                  </span>
                </div>
              </div>

              <validation-provider
                rules="required"
                v-slot="{ classes, errors }"
                :name="selectedCurrency.crypto ? $t('withdrawal-wallet') : $t('withdrawal-bank')"
              >
                <div class="input-group">
                  <multiselect
                    v-model="selectedBank"
                    deselect-label=""
                    select-label=""
                    selected-label=""
                    track-by="bank_id"
                    :class="classes"
                    :label="$t('withdrawal-bank')"
                    :placeholder="$t('withdrawal-dropdownCryptoWallet')"
                    :options="wallet"
                    :searchable="false"
                    :allow-empty="false"
                  >
                    <template slot="singleLabel" slot-scope="{ option }"
                      >{{ `${option.label} ${option.address}` }}
                    </template>
                    <template slot="option" slot-scope="{ option }">{{ `${option.label} ${option.address}` }}</template>
                    <template slot="noOptions">{{ $t('noOptions') }}</template>
                  </multiselect>
                  <span class="error__message">{{ errors[0] }}</span>
                </div>
              </validation-provider>
            </div>
            <div class="form-group">
              <div class="row">
                <label class="col-6" v-if="selectedCurrency.crypto">{{ $t('withdrawal-labelAmountCrypto') }}</label>
                <label class="col-6" v-else>{{ $t('withdrawal-labelAmount') }}</label>
              </div>
              <div class="row"></div>
              <div class="row">
                <validation-provider
                  :rules="'required|max_value:' + withdrawalBalanceNotFormatted"
                  :name="selectedCurrency.crypto ? $t('withdrawal-labelAmountCrypto') : $t('withdrawal-labelAmount')"
                  v-slot="{ classes, errors }"
                  class="col-6"
                >
                  <currency-input
                    id="currency_amount"
                    class="form-control"
                    :class="classes"
                    v-model="amount"
                    :precision="selectedCurrency.crypto ? 8 : 2"
                    :placeholder="
                      selectedCurrency.crypto ? $t('withdrawal-labelAmountCrypto') : $t('withdrawal-labelAmount')
                    "
                  />
                  <span class="error__message">{{ errors[0] }}</span>
                </validation-provider>
                <div class="col-6">
                  <div class="available-limit">
                    <p class="mb-0 font-small-2">{{ $t('withdrawal-availablelimit') }}</p>
                    <h6 class="mb-0 font-medium-5">{{ withdrawalBalance }}</h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 text-center mt-2">
              <button type="submit" class="btn-gradient-primary btn-block">{{ $t('withdrawal-btnContinue') }}</button>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="input-group">
            <label class="col-6">{{ $t('withdrawal-labelAmount') }} ({{ selectedCurrency.code }})</label>
            {{ amount }}
          </div>
          <div class="input-group">
            <label class="col-6">{{ $t('withdrawal-labelCommission') }} ({{ selectedCurrency.code }})</label>
            {{ transfer.limits.fee }}
          </div>
          <div class="input-group">
            <label class="col-6">{{ $t('withdrawal-labelTotalAmount') }} ({{ selectedCurrency.code }})</label>
            {{ amount - transfer.limits.fee }}
          </div>
          <div class="col-12 text-center mt-2"></div>
          <div class="d-flex text-center mt-2">
            <div class="col-6 text-center mt-2">
              <a type="button" class="btn btn-outline-secondary -border" @click="cancelTransfer">
                {{ $t('withdrawal-btnCancel') }}
              </a>
            </div>
            <div class="col-6 text-center mt-2">
              <button type="button" class="btn-gradient-primary" @click="createWithdrawal">
                {{ $t('withdrawal-btnConfirm') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <BankList />
        <div class="col-12 text-center mt-2">
          <button
            type="button"
            class="btn btn-outline-secondary -border mr-md-2"
            @click="$store.commit('withdrawal/showAddBankAccountUpdate', false)"
          >
            {{ $t('withdrawal-back') }}
          </button>
          <button type="button" class="btn-gradient-primary mt-1 mt-md-0" @click="addBankWalletBtn">
            {{ $t('withdrawal-addAccountModal') }}
          </button>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import i18n from '../../config/_i18n';

import Multiselect from 'vue-multiselect';

import BankList from '@/components/withdrawal/BankList';

import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import MessagesTR from 'vee-validate/dist/locale/tr.json';
import MessagesEN from 'vee-validate/dist/locale/en.json';
import MessagesNL from 'vee-validate/dist/locale/nl.json';
import MessagesME from 'vee-validate/dist/locale/en.json';
import MessagesET from 'vee-validate/dist/locale/et.json';
import MessagesDE from 'vee-validate/dist/locale/de.json';
import MessagesFR from 'vee-validate/dist/locale/fr.json';

let Messages;
if (i18n.locale === 'tr') {
  Messages = MessagesTR;
} else if (i18n.locale === 'nl') {
  Messages = MessagesNL;
} else if (i18n.locale === 'me') {
  Messages = MessagesME;
} else if (i18n.locale === 'et') {
  Messages = MessagesET;
} else if (i18n.locale === 'de') {
  Messages = MessagesDE;
} else if (i18n.locale === 'fr') {
  Messages = MessagesFR;
} else {
  Messages = MessagesEN;
}
Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule],
    message: Messages
  });
});

export default {
  name: 'WithdrawalForm',
  computed: {
    ...mapState({
      wallet: (state) => state.withdrawal.wallet,
      withdrawalBalance: (state) => state.withdrawal.withdrawalBalance,
      withdrawalBalanceNotFormatted: (state) => state.withdrawal.withdrawalBalanceNotFormatted,
      showAddBankAccount: (state) => state.withdrawal.showAddBankAccount,
      transfer: (state) => state.withdrawal.transfer,
      currency: (state) => state.global.currency,
      isLoading: (state) => state.global.isLoading,
      user: (state) => state.customer.user
    }),
    selectedBank: {
      get() {
        return this.$store.state.withdrawal.selectedBank;
      },
      set(value) {
        this.$store.commit('withdrawal/updateSelectedBank', value);
      }
    },
    amount: {
      get() {
        return this.$store.state.withdrawal.amount;
      },
      set(value) {
        this.$store.commit('withdrawal/updateAmount', value);
      }
    },
    selectedCurrency: {
      get() {
        return this.$store.state.withdrawal.selectedCurrency;
      },
      set(value) {
        this.$store.commit('withdrawal/updateSelectedCurrency', value);
      }
    },
    isTransfer: {
      get() {
        return this.$store.state.withdrawal.isTransfer;
      },
      set(value) {
        this.$store.commit('withdrawal/SET_CREATE_WITHDRAWAL', value);
      }
    },
    withdrawCurrency: function () {
      return this.currency.filter(function (currency) {
        return currency.withdraw;
      });
    }
  },
  components: {
    BankList,
    Multiselect,
    ValidationObserver,
    ValidationProvider
  },
  methods: {
    ...mapActions('global', ['GET_CURRENCY']),
    ...mapActions('withdrawal', [
      'GET_WITHDRAWAL',
      'POST_CREATE_WITHDRAWAL',
      'GET_PROVIDERS',
      'GET_BALANCE',
      'GET_HISTORY'
    ]),
    createWithdrawal() {
      this.POST_CREATE_WITHDRAWAL();
    },
    getProvidersBtn() {
      if ([10].includes(this.user.level)) this.$toast.error(this.$t('withdrawal-lvlMessage'));
      else this.GET_PROVIDERS();
    },
    getCoinSelection() {
      this.amount = null;
      this.selectedBank = '';
      this.GET_HISTORY({ currency: this.selectedCurrency.code.toLowerCase(), isNextPage: false });
      this.GET_WITHDRAWAL(this.selectedCurrency);
      this.GET_BALANCE(this.selectedCurrency.code.toLowerCase());
      this.$refs.form.reset();
    },
    addWalletBtn() {
      if (!this.selectedCurrency.crypto) {
        this.$store.commit('withdrawal/showAddBankAccountUpdate', true);
      } else {
        this.$store.commit('withdrawal/showAddBankAccountUpdate', false);
        this.$store.commit('withdrawal/showAddWalletModalUpdate', true);
      }
    },
    addBankWalletBtn() {
      this.$store.commit('withdrawal/showAddWalletModalUpdate', true);
    },
    cancelTransfer() {
      this.$store.commit('withdrawal/SET_CREATE_WITHDRAWAL', false);
    }
  },
  created() {
    this.GET_CURRENCY();

    //this.GET_WITHDRAWAL(this.selectedCurrency);
    //this.GET_BALANCE(this.selectedCurrency.code.toLowerCase());
  },
  watch: {
    withdrawCurrency: function (val) {
      this.selectedCurrency = val[0];
      this.getCoinSelection();
    }
  }
};
</script>

<style lang="scss" scoped>
.add-wallet {
  cursor: pointer;
  color: #1b65d3;
  @media only screen and (max-width: 768px) {
    padding: 20px 0 20px 5px;
    text-align: center;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }
}

.available-limit {
  text-align: right;
  padding-top: 5px;
}
</style>

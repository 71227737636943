/* eslint-disable no-debugger */
import Vue from 'vue';
import API from '@/api';
import Services from '@/config/_axios';
import i18n from '../../config/_i18n';

const qs = require('qs');

const deposit = {
  state: () => ({
    selectedCurrency:
      i18n.locale === 'tr' ? { code: 'TRY', name: 'Türk Lirası' } : { code: 'TRY', name: 'Turkish Liras' },
    userBalance: 0,
    amount: null,
    wallet: [],
    bankList: [],
    selectedBank: null,
    selectedBankCurrency: {
      code: 'TRY',
      name: 'Banka'
    },
    transfer: {},
    isCrypto: false,
    isDeposit: false,
    history: [],
    next: ''
  }),
  mutations: {
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_HISTORY(state, payload) {
      state.next = payload.payload.links.next;
      if (!payload.isNextPage) {
        state.history = [];
      }
      for (let idx in payload.payload.data) {
        state.history.push(payload.payload.data[idx]);
      }
    },
    SET_DEPOSIT(state, payload) {
      if (state.selectedCurrency.crypto === true) {
        state.wallet = payload.data.crypto_currency.providers[0];
      } else {
        state.bankList = [];
        state.bankList = payload.data.bank_transfer.providers;
      }
    },
    SET_CURRENCY_BALANCE(state, payload) {
      state.userBalance = payload.data.available_formatted;
    },
    updateSelectedCurrency(state, value) {
      state.selectedCurrency = value;
    },
    updateSelectedBank(state, value) {
      state.selectedBank = value;
      if (value == '8') {
        state.selectedBankCurrency = 'GBP';
      } else {
        state.selectedBankCurrency = 'TRY';
      }
    },
    updateAmount(state, value) {
      state.amount = value;
    },
    CLEAR_DEPOSIT(state) {
      state.amount = null;
      state.selectedBank = null;
    },
    SET_CREATE_DEPOSIT(state, payload) {
      state.isDeposit = payload ? true : false;
      state.transfer = payload ? payload.data : payload;
    }
  },
  actions: {
    GET_HISTORY({ state, commit }, params) {
      let nextPage = `${API.depositHistory}${state.selectedCurrency.code}?page=1`;
      if (params.isNextPage) {
        nextPage = `${state.next}&currency=${state.selectedCurrency.code}`;
      }
      Services.get(nextPage)
        .then(res => {
          let data = {
            payload: res.data,
            isNextPage: params.isNextPage
          };
          commit('SET_HISTORY', data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_DEPOSIT_PROVIDERS({ commit }, currency) {
      Services.get(`${API.depositProviders}/${currency}`)
        .then(res => {
          commit('SET_DEPOSIT', res.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },

    GET_CURRENCY_BALANCE({ commit }, currency) {
      Services.get(API.currencyBalance + currency)
        .then(res => {
          commit('SET_CURRENCY_BALANCE', res.data);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_CREATE_DEPOSIT({ state, commit }) {
      commit('IS_LOADING', true);
      Services.post(
        API.depositCreate,
        qs.stringify({
          provider_id: state.selectedBank.id,
          currency: state.selectedCurrency.code,
          amount: state.amount
        })
      )
        .then(res => {
          commit('SET_CREATE_DEPOSIT', res.data);
          Vue.$toast.success(res.data.message);
          commit('IS_LOADING', false);
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    PUT_APPROVE_DEPOSIT({ dispatch, state, commit }) {
      commit('IS_LOADING', true);
      Services.put(`${API.depositApprove}${state.transfer.code}`)
        .then(res => {
          commit('SET_CREATE_DEPOSIT', null);
          Vue.$toast.success(res.data.message);
          commit('IS_LOADING', false);
          commit('CLEAR_DEPOSIT');
          dispatch('GET_HISTORY', { currency: state.selectedCurrency, isNextPage: false });
        })
        .catch(err => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    CANCEL_DEPOSIT({ commit }) {
      commit('SET_CREATE_DEPOSIT', null);
    }
  },
  getters: {},
  namespaced: true
};

export default deposit;

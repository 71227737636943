<template>
  <div id='wallet'>
    <div class='wallet-table-th d-none'>
      <div class='row'>
        <div class='col-md-7 col-12 py-1'>
          <p class='mt-0 text-capitalize'>{{ $t('wallet-label-cryptotype') }}</p>
        </div>
        <div class='col-md-2 col-12 py-1 text-center'>
          <p class='mt-0 text-capitalize'>{{ $t('wallet-qr') }}</p>
        </div>
        <div class='col-md-3 col-12 py-1 text-center'>
          <p class='mt-0 text-capitalize'>{{ $t('wallet-availablelimit') }}</p>
        </div>
      </div>
    </div>
    <div v-if='isLoading'>
      <content-loader
        :width='1000'
        :height='83'
        :speed='2'
        primaryColor='#ffffff'
        secondaryColor='#eee'
        class='loading-bg'
        v-for='(item, index) in 7'
        :key="'loading-' + index"
      >
        <rect x='0' y='0' rx='6' ry='6' width='1000' height='83' />
      </content-loader>
    </div>
    <template v-else-if='wallet.length === 0'>
      <section class='card pull-up'>
        <div class='card-content'>
          <div class='card-body'>
            <div class='text-center'>{{ $t('wallet-detail-datatable-noData') }}</div>
          </div>
        </div>
      </section>
    </template>
    <section class='card pull-up' v-else v-for='(item, index) in wallet' :key="'wallet-' + index">
      <div class='card-content'>
        <div class='card-body'>
          <div class='col-12'>
            <div class='row justify-content-md-center align-items-md-center'>
              <div class='col-md-7 col-12'>
                <div class='media align-items-center'>
                  <img :src='item.currency_icon' class='cc mr-2 font-large-2 warning d-none d-md-block' height='30'
                       alt='' />
                  <div class='media-body'>
                    <div class='mt-0 text-capitalize currency-name'>{{ item.currency_name }} {{
                        item.currency_code
                      }}
                    </div>
                    <div class='text-muted mr-2 font-small-3 wallet-address' v-if='item.wallet'>
                      {{ item.wallet.address }}<br />
                      <template v-if="item.currency_code == 'XRP'">
                        <strong>{{ $t('deposit-walletTag') }}</strong> {{ targetValue }}
                      </template>
                      <template v-if="item.currency_code == 'XLM'">
                        <strong>{{ $t('deposit-memo') }}</strong> {{ targetValue }}
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class='col-md-2 col-12 text-center d-none d-md-block'>
                <div v-if='item.wallet' class='qrcode'>
                  <div
                    :style='{ backgroundImage: `url(${item.wallet.qr_image_code})` }'
                    class='img-zoom'
                  ></div>
                </div>
              </div>
              <div class='col-md-3 col-12 text-md-right'>
                <div class='font-weight-bold'>{{ item.available_formatted }}</div>
                <p class='text-muted mb-0 font-small-3'>{{ item.default_currency_total_formatted }}</p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ContentLoader } from 'vue-content-loader';

export default {
  name: 'WalletList',
  computed: {
    ...mapState({
      user: state => state.customer.user,
      wallet: state => state.wallet.wallet,
      isLoading: state => state.wallet.isLoading,
      series: state => state.wallet.series
    }),
    targetValue: function() {
      return this.user.id + 1000000000;
    }
  },
  components: {
    ContentLoader
  },
  created() {
    this.GET_BALANCE();
  },
  methods: {
    ...mapActions('wallet', ['GET_BALANCE'])
  }
};
</script>

<style lang='scss' scoped>
.currency-name {
  font-weight: bold;
  font-size: 20px;
}

.qrcode {
  height: 50px;
  position: relative;
  z-index: 1;

  .img-zoom {
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    width: 50px;
    height: 50px;
    background-position: center;
    background-size: contain;
    position: absolute;
    display: inline-block;
  }

  &:hover {
    z-index: 9;

    .img-zoom {
      width: 200px;
      height: 200px;
      border: 1px solid #e8e8e8;
      border-radius: 10px;
      box-shadow: 0 0 40px 32px rgba(0, 0, 0, 0.09);
    }
  }
}

</style>

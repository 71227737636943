<template>
  <footer class="footer footer-static footer-transparent">
    <p class="clearfix blue-grey lighten-2 text-sm-center mb-0 px-2 mt-2">
      <span class="d-block d-md-inline-block">
        <a :href="$t('footer-menu-contact-link')" target="_blank" class="blue-grey mr-1">
          {{ $t('footer-menu-contact') }}
        </a>
        <a :href="$t('footer-menu-aggrement-link')" target="_blank" class="blue-grey mr-1">
          {{ $t('footer-menu-aggrement') }}
        </a>
        <a :href="$t('footer-menu-membership-link')" target="_blank" class="blue-grey mr-1">
          {{ $t('footer-menu-membership') }}
        </a>
        <a :href="$t('footer-menu-KYC-link')" target="_blank" class="blue-grey mr-1">{{ $t('footer-menu-KYC') }}</a>
        <a :href="$t('footer-menu-AML-link')" target="_blank" class="blue-grey mr-1">{{ $t('footer-menu-AML') }}</a>
        Copyright &copy; 2021
        <a class="text-bold-800 grey darken-2" href="/" target="_blank">Miracle Cash&More </a>, All rights reserved.
      </span>
    </p>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  components: {}
};
</script>

<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form class="form-horizontal form-signin" method="post" @submit.prevent="handleSubmit(registerForm)">
        <div class="row">
          <validation-provider
            rules="required"
            :name="$t('register-corporateCompanyName')"
            v-slot="{ classes, errors }"
            class="col-12 col-md-6"
          >
            <fieldset class="form-label-group">
              <input
                type="text"
                class="form-control"
                id="company-corporate"
                v-model="register.corporate.company"
                :placeholder="$t('register-corporateCompanyName')"
                :class="classes"
              />
              <label for="company-corporate">{{ $t('register-corporateCompanyName') }}</label>
              <span class="error__message">{{ errors[0] }}</span>
            </fieldset>
          </validation-provider>
          <validation-provider
            :name="$t('register-corporateTaxNumber')"
            v-slot="{ classes, errors }"
            class="col-12 col-md-6"
          >
            <fieldset class="form-label-group">
              <input
                type="text"
                class="form-control"
                id="tax-number-corporate"
                v-model="register.corporate.tax_number"
                :placeholder="$t('register-corporateTaxNumber')"
                :class="classes"
              />
              <label for="tax-number-corporate">{{ $t('register-corporateTaxNumber') }}</label>
              <span class="error__message">{{ errors[0] }}</span>
            </fieldset>
          </validation-provider>
        </div>
        <div class="row">
          <validation-provider
            rules="required"
            :name="$t('register-country')"
            v-slot="{ classes, errors }"
            class="col-xl-6 col-lg-6 col-md-12"
          >
            <fieldset class="form-label-group">
              <multiselect
                v-model="register.corporate.country"
                :placeholder="$t('register-country')"
                label="title"
                track-by="title"
                :options="countries"
                :option-height="104"
                :show-labels="false"
                @select="change_countries"
                class="multiselectRegular"
                :class="classes"
                :allow-empty="false"
              >
                <template slot="singleLabel" slot-scope="props">
                  <div class="option">
                    <img class="option__image" :src="props.option.img" :alt="props.option.title" />
                    <span class="option__title">{{ props.option.title }}</span>
                  </div>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option">
                    <img class="option__image" :src="props.option.img" :alt="props.option.title" />
                    <span class="option__title">{{ props.option.title }}</span>
                  </div>
                </template>
                <template slot="noOptions">{{ $t('noOptions') }}</template>
              </multiselect>
              <span class="error__message">{{ errors[0] }}</span>
            </fieldset>
          </validation-provider>
          <validation-provider
            rules="required"
            :name="$t('register-city')"
            v-slot="{ classes, errors }"
            class="col-xl-6 col-lg-6 col-md-12"
          >
            <fieldset class="form-label-group">
              <multiselect
                v-model="register.corporate.city"
                :placeholder="$t('register-city')"
                label="title"
                track-by="title"
                :options="citiesFilteredList"
                :option-height="104"
                :show-labels="false"
                @select="change_city"
                class="multiselectRegular"
                :class="classes"
                :allow-empty="false"
                @search-change="(val) => searchCities(val)"
                :internal-search="false"
              >
                <template slot="singleLabel" slot-scope="props">
                  <div class="option">
                    <span class="option__title">{{ props.option.title }}</span>
                  </div>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option">
                    <span class="option__title">{{ props.option.title }}</span>
                  </div>
                </template>
                <template slot="noOptions">{{ $t('noOptions') }}</template>
              </multiselect>
              <span class="error__message">{{ errors[0] }}</span>
            </fieldset>
          </validation-provider>
        </div>
        <validation-provider :name="$t('register-taxBranches')" v-slot="{ classes, errors }">
          <fieldset class="form-label-group">
            <input
              type="text"
              class="form-control"
              id="taxBranches-corporate"
              v-model="register.corporate.taxOffice"
              :placeholder="$t('register-taxBranches')"
              :class="classes"
            />
            <label for="taxBranches-corporate">{{ $t('register-taxBranches') }}</label>
            <span class="error__message">{{ errors[0] }}</span>
          </fieldset>
        </validation-provider>
        <div class="row">
          <validation-provider
            rules="required"
            :name="$t('register-corporateName')"
            v-slot="{ classes, errors }"
            class="col-12 col-md-6"
          >
            <fieldset class="form-label-group">
              <input
                type="text"
                class="form-control"
                id="name-corporate"
                v-model="register.corporate.name"
                :placeholder="$t('register-corporateName')"
                :class="classes"
              />
              <label for="name-corporate">{{ $t('register-corporateName') }}</label>
              <span class="error__message">{{ errors[0] }}</span>
            </fieldset>
          </validation-provider>
          <validation-provider
            rules="required"
            :name="$t('register-corporateSurname')"
            v-slot="{ classes, errors }"
            class="col-12 col-md-6"
          >
            <fieldset class="form-label-group">
              <input
                type="text"
                class="form-control"
                id="surname-corporate"
                v-model="register.corporate.surname"
                :placeholder="$t('register-corporateSurname')"
                :class="classes"
              />
              <label for="surname-corporate">{{ $t('register-corporateSurname') }}</label>
              <span class="error__message">{{ errors[0] }}</span>
            </fieldset>
          </validation-provider>
        </div>
        <validation-provider rules="required" :name="$t('register-corporateEmail')" v-slot="{ classes, errors }">
          <fieldset class="form-label-group">
            <input
              type="mail"
              class="form-control"
              id="email-corporate"
              v-model="register.corporate.email"
              :placeholder="$t('register-corporateEmail')"
              :class="classes"
            />
            <label for="email-corporate">{{ $t('register-corporateEmail') }}</label>
            <span class="error__message">{{ errors[0] }}</span>
          </fieldset>
        </validation-provider>
        <div class="row">
          <validation-provider
            rules="required|min:8|strongPassword"
            :name="$t('register-individualPassword')"
            v-slot="{ classes, errors }"
            class="col-12 col-md-6"
          >
            <fieldset class="form-label-group">
              <i
                class="showPassword"
                @click="switchPasswordType()"
                v-bind:class="passwordFieldType === 'password' ? 'passwordEyeOff' : 'passwordEye'"
              ></i>
              <input
                :type="passwordFieldType"
                class="form-control"
                id="user-password-corporate"
                v-model="register.corporate.password"
                :placeholder="$t('register-individualPassword')"
                :class="classes"
              />
              <label for="user-password-corporate">{{ $t('register-individualPassword') }}</label>
              <span class="error__message">{{ errors[0] }}</span>
            </fieldset>
          </validation-provider>
          <validation-provider
            :rules="'required|min:8|password:@' + $t('register-individualPassword')"
            :name="$t('register-individualPasswordRepeat')"
            v-slot="{ classes, errors }"
            class="col-12 col-md-6"
          >
            <fieldset class="form-label-group">
              <input
                type="password"
                class="form-control"
                id="user-password-again-corporate"
                v-model="register.corporate.passwordAgain"
                :placeholder="$t('register-individualPasswordRepeat')"
                :class="classes"
              />
              <label for="user-password-again-corporate">{{ $t('register-individualPasswordRepeat') }}</label>
              <span class="error__message">{{ errors[0] }}</span>
            </fieldset>
          </validation-provider>
        </div>

        <fieldset>
          <div class="input-group">
            <multiselect
              v-model="register.corporate.phoneCode"
              :placeholder="$t('login-phone-code')"
              label="title"
              track-by="title"
              :options="countries"
              :option-height="104"
              :show-labels="false"
              :allow-empty="false"
              class="phoneCode"
            >
              <template slot="singleLabel" slot-scope="props">
                <div class="option">
                  <img class="option__image" :src="props.option.img" :alt="props.option.title" />
                  <span class="option__title">{{ props.option.code }}</span>
                </div>
              </template>
              <template slot="option" slot-scope="props">
                <div class="option">
                  <img class="option__image" :src="props.option.img" :alt="props.option.title" />
                  <span class="option__title">{{ props.option.code }}</span>
                </div>
              </template>
              <template slot="noOptions">{{ $t('noOptions') }}</template>
            </multiselect>
            <validation-provider
              :name="$t('register-individualPhone')"
              :rules="`required|min: ${minPhoneCount}`"
              v-slot="{ classes, errors }"
              class="form-label-group -wd"
            >
              <the-mask
                class="form-control"
                :class="classes"
                type="tel"
                id="phone-corporate"
                v-model="register.corporate.phone"
                :placeholder="$t('register-individualPhone')"
                :mask="[...register.corporate.phoneCode.phoneMask]"
              />
              <label for="phone-corporate">{{ $t('register-individualPhone') }}</label>
              <span class="error__message">{{ errors[0] }}</span>
            </validation-provider>
          </div>
        </fieldset>
        <validation-provider rules="required" :name="$t('register-aggrement')" v-slot="{ classes, errors }">
          <fieldset class="form-label-group -regular" :class="classes">
            <input
              type="checkbox"
              style="margin: 5px"
              id="accept-contract-corporate"
              v-model="register.corporate.acceptContract"
            />
            <label
              for="accept-contract-corporate"
              class="ml-1"
              @click="showPolicyModal = true"
              v-html="$t('register-individualPolicy')"
            ></label>
          </fieldset>
          <span class="error__message">{{ errors[0] }}</span>
        </validation-provider>
        <button type="submit" class="btn-gradient-primary text-capitalize btn-block my-1">
          {{ $t('register-btnIndividualRegister') }}
        </button>
        <router-link to="/login" class="btn btn-outline-primary btn-block full-radius">
          {{ $t('register-btnIndividualLogin') }}
        </router-link>
      </form>
    </ValidationObserver>
    <modal v-if="showRegisterModal" ref="modalName">
      <template v-slot:header>
        <h1>{{ $t('modal-title-success') }}</h1>
      </template>
      <template v-slot:body>
        {{ showRegisterModalMessage }}
      </template>
      <template v-slot:footer>
        <router-link @click.native="clearModal" to="/login" class="btn btn-outline-primary full-radius">
          {{ $t('modal-btn') }}
        </router-link>
      </template>
    </modal>
    <modal v-if="showPolicyModal" ref="modalName">
      <template v-slot:header>
        <h1></h1>
      </template>
      <template v-slot:body>
        <div v-html="staticContent.content"></div>
      </template>
    </modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import i18n from '../../config/_i18n';

import Modal from '@/components/global/Modal';
import Multiselect from 'vue-multiselect';

import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import MessagesTR from 'vee-validate/dist/locale/tr.json';
import MessagesEN from 'vee-validate/dist/locale/en.json';
import MessagesNL from 'vee-validate/dist/locale/nl.json';
import MessagesME from 'vee-validate/dist/locale/en.json';
import MessagesET from 'vee-validate/dist/locale/et.json';
import MessagesDE from 'vee-validate/dist/locale/de.json';
import MessagesFR from 'vee-validate/dist/locale/fr.json';

let Messages, PasswordErrorMessage, StrongPasswordErrorMessage;
if (i18n.locale === 'tr') {
  Messages = MessagesTR;
  PasswordErrorMessage = 'Parola  eşleşmiyor';
  StrongPasswordErrorMessage =
    'Parolanız en az bir küçük harf, bir büyük harf ve bir rakam içermelidir. Özel karakterler de kullanabilirsiniz';
} else if (i18n.locale === 'nl') {
  Messages = MessagesNL;
  PasswordErrorMessage = 'Password confirmation does not match';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
} else if (i18n.locale === 'me') {
  Messages = MessagesME;
  PasswordErrorMessage = 'Password confirmation does not match';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
} else if (i18n.locale === 'et') {
  Messages = MessagesET;
  PasswordErrorMessage = 'Password confirmation does not match';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
} else if (i18n.locale === 'de') {
  Messages = MessagesDE;
  PasswordErrorMessage = 'Password confirmation does not match';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
} else if (i18n.locale === 'fr') {
  Messages = MessagesFR;
  PasswordErrorMessage = 'Password confirmation does not match';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
} else {
  Messages = MessagesEN;
  PasswordErrorMessage = 'Password confirmation does not match';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
}
console.log(Messages);
Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule],
    message: Messages.messages[rule]
  });
});

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: PasswordErrorMessage
});
extend('strongPassword', {
  validate(value) {
    const strongRegex = new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).+$');
    return strongRegex.test(value);
  },
  message: StrongPasswordErrorMessage
});

export default {
  name: 'Corporate',
  components: {
    Multiselect,
    Modal,
    ValidationProvider,
    ValidationObserver
  },
  props: {
    staticContent: Object
  },
  data() {
    return {
      passwordFieldType: 'password',
      minPhoneCount: 7,
      citySearch: ''
    };
  },
  methods: {
    ...mapActions('customer', ['GET_COUNTRIES', 'CHANGE_COUNTRIES', 'CHANGE_CITIES', 'POST_CORPORATE_REGISTER']),
    registerForm() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }
        this.POST_CORPORATE_REGISTER();
      });
    },
    change_countries(option) {
      this.CHANGE_COUNTRIES(option);
    },
    change_city(option) {
      this.CHANGE_CITIES(option);
    },
    searchCities(val) {
      console.log(val);
      this.citySearch = val;
    },
    clearModal() {
      this.$store.commit('customer/closeModal', false);
    },
    switchPasswordType() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    }
  },
  computed: {
    ...mapState({
      register: (state) => state.customer.register,
      countries: (state) => state.customer.countries,
      cities: (state) => state.customer.cities,
      taxbranches: (state) => state.customer.taxbranches,
      showRegisterModal: (state) => state.customer.showRegisterModal,
      showRegisterModalMessage: (state) => state.customer.showRegisterModalMessage
    }),
    showPolicyModal: {
      get() {
        return this.$store.state.customer.showPolicyModal;
      },
      set(value) {
        this.$store.commit('customer/updatePolicyModal', value);
      }
    },
    getPhoneCount: function () {
      const arrayList = this.register.corporate.phoneCode.phoneMask;
      let countArray = [];
      arrayList.forEach(function (elem) {
        countArray.push(elem.split('#').length - 1);
      });
      return Math.min(...countArray) - 1;
    },
    citiesFilteredList() {
      return this.cities.filter((item) => {
        return this.citySearch
          ?.toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .split(' ')
          .every((v) =>
            item.title
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .includes(v)
          );
      });
    }
  },
  watch: {
    'register.corporate.phoneCode': {
      handler() {
        this.register.personel.phone = '';
        const arrayList = this.register.corporate.phoneCode.phoneMask;
        let countArray = [];
        arrayList.forEach(function (elem) {
          countArray.push(elem.split('#').length - 1);
        });
        this.minPhoneCount = Math.min(...countArray) - 1;
      }
    }
  },
  created() {
    this.GET_COUNTRIES();
  }
};
</script>

<style lang="scss" scoped>
.multiselect {
  &.phoneCode {
    width: 110px;
  }

  &__tags {
    height: 54.5px;
    padding: 8px 30px 0 8px;
    border-radius: 5px;
    border: 1px solid #cacfe7;
    color: #3b4781;
    line-height: 1.5rem;
    border-right: 0;
  }

  &__input,
  &__single {
    min-height: 37px;
    line-height: 37px;
  }

  &__select {
    height: 50px;
  }

  &__placeholder {
    line-height: 34px;
    color: #aeb5da;
  }
}

.option {
  display: flex;
  align-items: center;

  &__image {
    margin-right: 5px;
    height: 13px;
  }

  &__title {
    font-size: 12px;
  }
}

.-wd {
  width: calc(100% - 110px);

  .form-control {
    border-radius: 0 5px 5px 0;
  }
}

.multiselectRegular {
  width: 100%;

  > .multiselect__tags {
    border-radius: 0.25rem;
  }
}

.multiselect__placeholder {
  line-height: 34px;
  color: #aeb5da;
}

.modal__body > div {
  height: calc(100vh - 300px);
}
</style>

<template>
  <div class='content-wrapper'>
    <Breadcrumb :title="$t('miracle_deal.title')"/>
    <div class='row'>
      <div class='col-12'>
        <div class='card pull-up-only-shadow'>
          <div class='card-body'>
            <div class='row'>
              <div class='col-12 col-lg-3 '>
                <router-link :to="{ name: 'KeeperMyList'}"  class="btn btn-outline-primary round btn-block">
                  {{ $t('miracle_deal.keeper_my_list') }}
                </router-link>
                <router-link :to="{ name: 'KeeperList'}"  class="btn btn-outline-primary round btn-block">
                  {{ $t('miracle_deal.keeper_list_all') }}
                </router-link>
              </div>
              <div class='col-12 col-lg-3 '>
                <router-link :to="{ name: 'KeeperOrder'}"  class="btn btn-outline-primary round btn-block">
                  {{ $t('miracle_deal.keeper_order') }}
                </router-link>
              </div>
              <div class='col-12 col-lg-3 '>
                <router-link :to="{ name: 'StrikerMyList'}"  class="btn btn-outline-primary round btn-block">
                  {{ $t('miracle_deal.striker_my_list') }}
                </router-link>
                <router-link :to="{ name: 'StrikerList'}"  class="btn btn-outline-primary round btn-block">
                  {{ $t('miracle_deal.striker_list_all') }}
                </router-link>
              </div>
              <div class='col-12 col-lg-3 '>
                <router-link :to="{ name: 'StrikerOrder'}"  class="btn btn-outline-primary round btn-block">
                  {{ $t('miracle_deal.striker_order') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/layout/Breadcrumb';

export default {
  name: 'Deal',
  components: {
    Breadcrumb
  }
};
</script>

<template>
  <div>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
      <form method="post" @submit.prevent="handleSubmit(changePassword)" novalidate="novalidate">
        <validation-provider
          rules="required|min:8|strongPassword"
          :name="$t('pwSettings-curPassword')"
          v-slot="{ classes, errors }"
          class="form-group row"
        >
          <label for="curPassword" class="col-sm-4 col-form-label col-form-label-lg">
            {{ $t('pwSettings-curPassword') }}
          </label>
          <div class="col-sm-8">
            <i
              class="showPassword"
              @click="switchPasswordType()"
              v-bind:class="passwordFieldType === 'password' ? 'passwordEyeOff' : 'passwordEye'"
            ></i>
            <input
              id="curPassword"
              :type="passwordFieldType"
              v-model="passwords.curPassword"
              class="form-control form-control-lg"
              placeholder="***********"
              :class="classes"
            />
            <span class="error__message">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <validation-provider
          rules="required|min:8|strongPassword"
          :name="$t('pwSettings-newPassword')"
          v-slot="{ classes, errors }"
          class="form-group row"
        >
          <label for="curPassword" class="col-sm-4 col-form-label col-form-label-lg">
            {{ $t('pwSettings-newPassword') }}
          </label>
          <div class="col-sm-8">
            <i
              class="showPassword"
              @click="switchPasswordTypeSecond()"
              v-bind:class="passwordFieldTypeSecond === 'password' ? 'passwordEyeOff' : 'passwordEye'"
            ></i>
            <input
              id="newPassword"
              :type="passwordFieldTypeSecond"
              v-model="passwords.newPassword"
              class="form-control form-control-lg"
              placeholder="***********"
              :class="classes"
            />
            <span class="error__message">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <validation-provider
          :rules="'required|min:8|password:@' + $t('pwSettings-newPassword')"
          :name="$t('pwSettings-confirmPassword')"
          v-slot="{ classes, errors }"
          class="form-group row"
        >
          <label for="curPassword" class="col-sm-4 col-form-label col-form-label-lg">
            {{ $t('pwSettings-confirmPassword') }}
          </label>
          <div class="col-sm-8">
            <input
              id="reNewPassword"
              type="password"
              v-model="passwords.reNewPassword"
              class="form-control form-control-lg"
              placeholder="***********"
              :class="classes"
            />
            <span class="error__message">{{ errors[0] }}</span>
          </div>
        </validation-provider>
        <div class="row justify-content-center">
          <button type="button" @click="cancelPassword" class="btn btn-outline-primary round btn-block col-3 col-md-3">
            {{ $t('pwSettings-cancel') }}
          </button>
          <button type="submit" class="btn btn-gradient-primary round btn-block col-8 col-md-4 offset-1">
            {{ $t('pwSettings-changePassword') }}
          </button>
        </div>

        <div class="row justify-content-end">
          <div class="col-3"></div>
          <div class="col-3"></div>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import i18n from '../../config/_i18n';

import { extend, ValidationProvider, ValidationObserver } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import MessagesTR from 'vee-validate/dist/locale/tr.json';
import MessagesEN from 'vee-validate/dist/locale/en.json';
import MessagesNL from 'vee-validate/dist/locale/nl.json';
import MessagesME from 'vee-validate/dist/locale/en.json';
import MessagesET from 'vee-validate/dist/locale/et.json';
import MessagesDE from 'vee-validate/dist/locale/de.json';
import MessagesFR from 'vee-validate/dist/locale/fr.json';

let Messages, PasswordErrorMessage, StrongPasswordErrorMessage;
if (i18n.locale === 'tr') {
  Messages = MessagesTR;
  PasswordErrorMessage = 'Parola  eşleşmiyor';
  StrongPasswordErrorMessage =
    'Parolanız en az bir küçük harf, bir büyük harf ve bir rakam içermelidir. Özel karakterler de kullanabilirsiniz';
} else if (i18n.locale === 'nl') {
  Messages = MessagesNL;
  PasswordErrorMessage = 'Password confirmation does not match';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
} else if (i18n.locale === 'me') {
  Messages = MessagesME;
  PasswordErrorMessage = 'Password confirmation does not match';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
} else if (i18n.locale === 'et') {
  Messages = MessagesET;
  PasswordErrorMessage = 'Password confirmation does not match';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
} else if (i18n.locale === 'de') {
  Messages = MessagesDE;
  PasswordErrorMessage = 'Password confirmation does not match';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
} else if (i18n.locale === 'fr') {
  Messages = MessagesFR;
  PasswordErrorMessage = 'Password confirmation does not match';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
} else {
  Messages = MessagesEN;
  PasswordErrorMessage = 'Password confirmation does not match';
  StrongPasswordErrorMessage =
    'Your password must contain at least one lowercase letter, one uppercase letter, and one number. You can also use special characters.!';
}

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule],
    message: Messages.messages[rule]
  });
});

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: PasswordErrorMessage
});
extend('strongPassword', {
  validate(value) {
    const strongRegex = new RegExp('^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).+$');
    return strongRegex.test(value);
  },
  message: StrongPasswordErrorMessage
});
export default {
  name: 'PasswordForm',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      passwordFieldType: 'password',
      passwordFieldTypeSecond: 'password'
    };
  },
  computed: {
    ...mapState({
      passwords: (state) => state.account.passwords
    })
  },
  methods: {
    ...mapActions('account', ['POST_PASSWORD', 'CANCEL_PASSWORD']),
    changePassword() {
      this.$store
        .dispatch('account/POST_PASSWORD')
        .then(() => {
          this.$refs.form.reset();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelPassword() {
      this.CANCEL_PASSWORD();
    },
    switchPasswordType() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    switchPasswordTypeSecond() {
      this.passwordFieldTypeSecond = this.passwordFieldTypeSecond === 'password' ? 'text' : 'password';
    }
  }
};
</script>

<style lang="scss" scoped>
.showPassword {
  right: 30px;
}

@media screen and (max-width: 768px) {
  .btn-outline-primary {
    padding: 0;
  }
}
</style>

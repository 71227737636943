<template>
  <div class='row'>
    <div id='recent-transactions' class='col-12 mt-2'>
      <h3 class='my-2'>{{ $t('deposit-depositHistory') }}</h3>
      <div class='card'>
        <div class='card-content'>
          <div class='table-responsive'>
            <table id='recent-orders' class='table table-hover table-xl mb-0'>
              <thead>
              <tr>
                <th class='border-top-0'>{{ $t('deposit-datatable-date') }}</th>
                <th class='border-top-0'>{{ $t('deposit-datatable-operation') }}</th>
                <th class='border-top-0'>{{ $t('deposit-datatable-currency') }}</th>
                <th class='border-top-0'>{{ $t('deposit-datatable-total') }}</th>
                <th class='border-top-0'>{{ $t('deposit-datatable-status') }}</th>
              </tr>
              </thead>
              <tbody>
              <template v-if='isLoading'>
                <tr>
                  <td colspan='6' class='text-center loading-content'>
                    <content-loader
                      :width='1000'
                      :height='63'
                      :speed='2'
                      primaryColor='#ffffff'
                      secondaryColor='#eee'
                      class='loading-bg'
                      v-for='(item, index) in 3'
                      :key="'loading-' + index"
                    >
                      <rect x='0' y='0' rx='6' ry='6' width='1000' height='83' />
                    </content-loader>
                  </td>
                </tr>
              </template>
              <template v-else-if='history.length === 0'>
                <tr>
                  <td colspan='6' class='text-center'>{{ $t('deposit-depositHistoryNoData') }}</td>
                </tr>
              </template>
              <tr v-else v-for='(item, index) in history' :key="'history-' + index" :class="['progress-status',progressStatusColors['transfer'][item.status]]">
                <td class='text-truncate'>{{ item.created_at | dateFormat }}</td>
                <td class='text-truncate'>
                    <span class='progress-type deposit'>
                      {{ $t('deposit-datatable-deposit') }}
                    </span>
                </td>
                <td>
                  <i class='cc' v-bind:class="selectedCurrency.code.toUpperCase() + '-alt'"></i> {{ item.currency }}
                </td>
                <td class='text-truncate p-1'>{{ item.amount_formatted }}</td>
                <td class='text-truncate'>
                  <span class="progress-text d-flex justify-content-start align-items-center">
                  <i class='la la-dot-circle-o font-medium-1 m-r-5'></i> {{ item.status_str }}
                  </span>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class='col-12 text-center mt-2 mb-2' v-if='next != null'>
          <button type='button' class='btn-gradient-primary' @click='getMore'>{{ $t('deposit-loadMore') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dateFormat from '@/config/_date-format';
import { mapState, mapActions } from 'vuex';
import { ContentLoader } from 'vue-content-loader';

export default {
  name: 'DepositHistory',
  components: {
    ContentLoader
  },
  filters: {
    dateFormat
  },
  methods: {
    getMore() {
      if (this.next) this.GET_HISTORY({ isNextPage: true });
    },
    ...mapActions('deposit', ['GET_HISTORY'])
  },
  computed: {
    ...mapState({
      history: state => state.deposit.history,
      selectedCurrency: state => state.deposit.selectedCurrency,
      next: state => state.deposit.next,
      isLoading: state => state.global.isLoading,
      progressStatusColors: state => state.global.progressStatusColors
    })
  },
  created() {
    this.GET_HISTORY({ currency: this.selectedCurrency.code.toLowerCase(), isNextPage: false });
  }
};
</script>

<style scoped>
.btn-status {
  cursor: default !important;
}
</style>

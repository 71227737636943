/* eslint-disable no-debugger */
import Vue from 'vue';
import API from '@/api';
import Services from '@/config/_axios';
import i18n from '../../config/_i18n';

const qs = require('qs');

const withdrawal = {
  state: () => ({
    selectedCurrency: null,
    wallet: [],
    history: [],
    next: '',
    banks: [],
    isLoading: true,
    series: [],
    cryptoPrice: 0,
    withdrawalBalance: 0,
    withdrawalBalanceNotFormatted: 0,
    amount: null,
    selectedBank: '',
    selectedBankCurrency: null,
    showAddBankAccount: false,
    transfer: { limits: {} },
    isCrypto: false,
    isTransfer: false,
    showAddWalletModal: false,
    add: {
      provider: '',
      currency: '',
      address: '',
      bank: '',
      label: '',
      tag: ''
    }
  }),
  mutations: {
    SET_BALANCE(state, payload) {
      state.withdrawalBalance = payload.data.available_formatted;
      const stringPrice = payload.data.available_formatted.replace(/[^\d.]/g, '');
      const priceFloat = parseFloat(stringPrice);
      state.withdrawalBalanceNotFormatted = priceFloat;
    },
    SET_WITHDRAWAL(state, payload) {
      state.wallet = payload.data;
    },
    SET_BUY_NOW_PRICES(state, payload) {
      state.cryptoPrice = payload.data.price_buy / 100;
    },
    SET_PROVIDERS(state) {
      if (state.isCrypto === true) {
        //state.transfer = payload.data.crypto_currency;
        if (state.selectedBank.fee_type === 'ratio') {
          state.transfer.limits.fee = state.amount * state.selectedBank.fee;
        } else {
          state.transfer.limits.fee = state.selectedBank.fee;
        }
        state.isTransfer = true;
      } else {
        state.transfer.limits.fee = state.selectedBank.fee;
        if (state.amount < state.selectedBank.min_pay) {
          Vue.$toast.error(
            i18n._vm.messages[sessionStorage.getItem('lang')].withdrawalMinLimit +
              ' ' +
              state.selectedBank.min_pay_formatted
          );
          return;
        }
        state.isTransfer = true;
      }
    },
    SET_ADD_WALLET(state) {
      state.showAddWalletModal = false;
      state.showAddBankAccount = false;
    },
    SET_CREATE_WITHDRAWAL(state) {
      state.isTransfer = false;
    },
    IS_LOADING(state, payload) {
      state.isLoading = payload;
    },
    SET_BANKS(state, payload) {
      if (state.banks.length > 0) return;
      for (let key in payload.data) {
        state.banks.push(payload.data[key]);
      }
    },
    CLEAR_BALANCE(state) {
      state.wallet = [];
    },
    CLEAR_WALLET_FORM(state) {
      state.add = {
        provider: '',
        currency: '',
        address: '',
        bank: '',
        label: '',
        tag: ''
      };
    },
    updateAmount(state, value) {
      state.amount = value;
    },
    updateSelectedBank(state, value) {
      state.selectedBank = value;
    },
    updateIsCrypto(state, value) {
      state.isCrypto = value;
    },
    updateSelectedCurrency(state, value) {
      state.selectedCurrency = value;
      state.isCrypto = value.crypto;
    },
    showAddWalletModalUpdate(state, playload) {
      state.showAddWalletModal = playload;
    },
    showAddBankAccountUpdate(state, playload) {
      state.showAddBankAccount = playload;
    },
    SET_HISTORY(state, payload) {
      state.next = payload.payload.links.next;

      if (!payload.isNextPage) {
        state.history = [];
      }
      for (let idx in payload.payload.data) {
        state.history.push(payload.payload.data[idx]);
      }
    }
  },
  actions: {
    GET_BALANCE({ commit }, currency) {
      Services.get(API.currencyBalance + currency)
        .then((res) => {
          commit('SET_BALANCE', res.data);
          this.dispatch('wallet/GET_BALANCE', { root: true });
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_BANKS({ commit }) {
      Services.get(API.banks)
        .then((res) => {
          commit('SET_BANKS', res.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_WITHDRAWAL({ commit }, currency) {
      let url;
      if (currency.crypto) {
        url = '/crypto_currency/' + currency.code.toLowerCase();
      } else {
        url = '/bank_transfer/' + currency.code.toLowerCase();
      }
      Services.get(API.withdraw + url)
        .then((res) => {
          commit('SET_WITHDRAWAL', res.data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_BUY_NOW_PRICES({ dispatch, commit }, currency) {
      Services.get(API.buyAndSellNowPrices + currency)
        .then((res) => {
          commit('SET_BUY_NOW_PRICES', res.data);
          dispatch('GET_PROVIDERS', currency);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_PROVIDERS({ commit }) {
      commit('SET_PROVIDERS');
    },
    POST_CREATE_WITHDRAWAL({ state, commit, dispatch }) {
      Services.post(
        API.withdrawCreate,
        qs.stringify({
          wallet_code: state.selectedBank,
          amount: state.amount
        })
      )
        .then((res) => {
          commit('SET_CREATE_WITHDRAWAL', res.data);
          Vue.$toast.success(res.data.message);
          dispatch('GET_HISTORY', { currency: state.selectedCurrency.code.toLowerCase(), isNextPage: false });
          dispatch('GET_BALANCE', state.selectedCurrency.code);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    POST_ADD_WALLET({ state, dispatch, commit }) {
      let address;
      if (state.isCrypto) {
        address = state.add.address;
      } else {
        address = state.add.address.trim().replace(/ +/g, '');
        let addressPrefix = state.add.bank.country.code;
        if (state.add.bank.type === 'UBAN') addressPrefix = 'ct';
        address = addressPrefix + address;
      }

      let request = {
        provider: !state.isCrypto ? 'bank_transfer' : 'crypto_currency',
        currency: !state.isCrypto ? state.add.currency.code : state.selectedCurrency.code,
        address: address,
        bank: state.add.bank.id,
        label: state.add.label
      };

      let walletTag = state.add.tag.trim();
      if (walletTag !== '') {
        request.tag = walletTag;
      }

      Services.post(API.createWallet, qs.stringify(request))
        .then((res) => {
          commit('SET_ADD_WALLET', res.data);
          Vue.$toast.success(res.data.message);
          dispatch('GET_WITHDRAWAL', state.selectedCurrency);
          commit('CLEAR_WALLET_FORM');
          commit('IS_LOADING', false);
          this.$refs.form.reset();
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    DELETE_BANK({ state, dispatch, commit }, bankCode) {
      Services.delete(API.withdraw + '/' + bankCode)
        .then((res) => {
          Vue.$toast.success(res.data.message);
          dispatch('GET_WITHDRAWAL', state.selectedCurrency);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    },
    GET_CLEAR_BALANCE({ commit }) {
      commit('CLEAR_BALANCE');
    },
    GET_HISTORY({ state, commit }, params) {
      let nextPage = `${API.withdrawHistory}${params.currency}?page=1`;
      if (params.isNextPage) {
        nextPage = `${state.next}&currency=${params.currency}`;
      }
      Services.get(nextPage)
        .then((res) => {
          let data = {
            payload: res.data,
            isNextPage: params.isNextPage
          };
          commit('SET_HISTORY', data);
          commit('IS_LOADING', false);
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            Vue.$toast.error(err.response.data.message);
          }
        });
    }
  },
  getters: {},
  namespaced: true
};

export default withdrawal;

<template>
<div class="modal fade" :class="{ show: showKeeperModal }">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">{{ getTitle }}</h5>
                <button type="button" class="close" @click='closeModal()'>
                    <i aria-hidden="true" class="ki ki-close"></i>
                </button>
            </div>
            <div class="modal-body">
                <form class="form" @submit.prevent="submitForm">
                    <div>
                        <div class="form-group">

                        </div>
                        <div class="form-group text-center mt-10">
                            <button type="submit" class="btn btn-outline-primary px-6">{{ $t('commons.submit') }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import {mapActions, mapState} from 'vuex'

export default {
  name: 'Modal',
  props: {
    postType: {
        type: String,
        default: 'keeper'
    }
  },
  data() {
    return {
      selectedUser: ''
    }
  },
  components: {
  },
  computed: {
    ...mapState({
        showKeeperModal: state => state.keeper.showKeeperModal,
        isLoading1: state => state.keeper.isLoading,
        selectedStrikerId: state => state.striker.selectedStrikerId,
        selectedKeeperId: state => state.keeper.selectedKeeperId
    }),
    getTitle() {
        if(this.postType === 'keeper') return 'Striker New';
        return 'Keeper New';
    }
  },
  methods: {
    ...mapActions('keeper', ['POST_KEEPER_NEW']),
    ...mapActions('striker', ['POST_STRIKER_NEW']),
    createKeeper() {
        let data = {
            user_id: this.selectedUser.id,
            keeper_id: this.selectedStrikerId
        };
        console.log(data)
        this.POST_STRIKER_NEW(data);
    },
    createStriker() {
        let data = {
            user_id: this.selectedUser.id,
            striker_id: this.selectedKeeperId
        };
        console.log(data)
        this.POST_KEEPER_NEW(data);
    },
    submitForm() {
        if(this.postType === 'keeper') {
            this.createStriker();
        } else if(this.postType === 'striker') {
            this.createKeeper()
        } else {
            this.$toast.error("Wrong type selected. Type can be keeper or striker!");
        }
    },
    getData(query){
        console.log(query)
        console.log("esc:",escape(query))
        this.SEARCH_USER_KEEPER(query)
        console.log(this.isLoading)
    },
    closeModal() {
        this.$store.commit('keeper/statusModal', false);
    },
  }
}
</script>

<style scoped>
.show {
    display: block;
}
</style>
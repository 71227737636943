/* eslint-disable no-debugger */
import Vue from 'vue';
import store from '@/store';
import VueRouter from 'vue-router';
//import Home from '../views/Home.vue'
import Dashboard from '../views/Dashboard.vue';
import Wallet from '../views/Wallet.vue';
import Deposit from '../views/Deposit.vue';
import InstantTrade from '../views/InstantTrade.vue';
import Withdrawal from '../views/Withdrawal.vue';
import Login from '../views/Login.vue';
import Register from '../views/Register.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import PasswordReset from '../views/PasswordReset.vue';
//import AccessSettings from '../views/Personal/AccessSettings.vue';
import AccountSettings from '../views/Personal/AccountSettings.vue';
import BankSettings from '../views/Personal/BankSettings.vue';
import History from '../views/Personal/History.vue';
import NotificationSettings from '../views/Personal/NotificationSettings.vue';
import PasswordSettings from '../views/Personal/PasswordSettings.vue';
import UserLoginSettings from '../views/Personal/UserLoginSettings.vue';
import Limit from '../views/Personal/Limit.vue';
import EmailVerify from '../views/EmailVerify.vue';
import Deal from '../views/Deal';
import KeeperList from '../views/KeeperList';
import KeeperMyList from '../views/KeeperMyList';
import StrikerList from '../views/StrikerList';
import StrikerMyList from '../views/StrikerMyList';
import KeeperOrder from '../views/KeeperOrder';
import StrikerOrder from '../views/StrikerOrder';

import API from '../api';
import Services from '../config/_axios';

import i18n from '../config/_i18n';

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters['customer/isAuthenticated']) {
    document.querySelector('body').classList.remove('fixed-navbar');
    localStorage.removeItem('refreshNextTime');
    next();
    return;
  }
  next('/');
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters['customer/isAuthenticated']) {
    document.querySelector('body').classList.add('fixed-navbar');
    next();
    return;
  }

  next('/login');
};

const ifAuthenticatedAndNotLevelTwo = (to, from, next) => {
  if (
    to.name !== 'AccountSettings' &&
    store.getters['customer/isAuthenticated'] &&
    store.getters['customer/userLevel'] < 30
  ) {
    Vue.$toast.error(i18n._vm.messages[sessionStorage.getItem('lang')].userLevelWarning);
    next('/personal/account-settings');
    return;
  } else if (store.getters['customer/isAuthenticated']) {
    document.querySelector('body').classList.add('fixed-navbar');
    next();
    return;
  }

  next('/login');
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    //component: Home,
    component: Wallet,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/wallet',
    name: 'Wallet',
    component: Wallet,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/deposit',
    name: 'Deposit',
    component: Deposit,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/instant-trade',
    name: 'InstantTrade',
    component: InstantTrade,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/withdrawal',
    name: 'Withdrawal',
    component: Withdrawal,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/deal',
    name: 'Deal',
    component: Deal,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/keeper/list',
    name: 'KeeperList',
    component: KeeperList,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/keeper/my-list',
    name: 'KeeperMyList',
    component: KeeperMyList,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/striker/list',
    name: 'StrikerList',
    component: StrikerList,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/striker/my-list',
    name: 'StrikerMyList',
    component: StrikerMyList,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/keeper/order',
    name: 'KeeperOrder',
    component: KeeperOrder,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/striker/order',
    name: 'StrikerOrder',
    component: StrikerOrder,
    beforeEnter: ifAuthenticatedAndNotLevelTwo
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/Register/EmailVerify',
    name: 'EmailVerify',
    component: EmailVerify,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    beforeEnter: ifNotAuthenticated
  },
  {
    path: '/PasswordReset',
    name: 'PasswordReset',
    component: PasswordReset,
    beforeEnter: ifNotAuthenticated
  },
  /*
  {
    path: '/personal/access-settings',
    name: 'AccessSettings',
    component: AccessSettings,
    beforeEnter: ifAuthenticated
  },
  */
  {
    path: '/personal/account-settings',
    name: 'AccountSettings',
    component: AccountSettings,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/personal/bank-settings',
    name: 'BankSettings',
    component: BankSettings,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/personal/history',
    name: 'History',
    component: History,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/personal/notification-settings',
    name: 'NotificationSettings',
    component: NotificationSettings,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/personal/password-settings',
    name: 'PasswordSettings',
    component: PasswordSettings,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/personal/user-login-settings',
    name: 'UserLoginSettings',
    component: UserLoginSettings,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/personal/user-limit',
    name: 'UserLimit',
    component: Limit,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    beforeEnter: ifAuthenticated
  }
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  routes
});

router.afterEach(() => {
  let refreshNextTime = window.localStorage.getItem('refreshNextTime');
  refreshNextTime = parseInt(refreshNextTime);

  if (refreshNextTime && refreshNextTime < Date.now()) {
    setTimeout(() => {
      Services.post(API.refreshToken)
        .then((res) => {
          localStorage.setItem('userToken', res.data.access_token);
          localStorage.setItem('refreshNextTime', Date.now() + 3600000);
          Services.defaults.headers.common['Authorization'] = res.data.access_token;
        })
        .catch((err) => {
          if (err?.response?.data?.message) {
            console.log(err.response.data.message);
          }
        });
    }, 3000);
  }
});

export default router;
